<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input placeholder="商品名称" v-model="params.goodsName" />
        </el-form-item>

        <!-- <el-form-item label="供应商" prop="supplierId">
          <el-select v-model="params.supplierId" clearable>
            <el-option
              v-for="item in supplierList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="条形码" prop="barNo">
          <el-input placeholder="条形码" v-model="params.barNo" />
        </el-form-item>

        <el-form-item label="品牌" prop="goodsBrand">
          <el-select v-model="params.goodsBrand" filterable clearable>
            <el-option
              v-for="item in brandList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品一级分类" prop="clazzId1">
          <el-select v-model="params.clazzId1" clearable>
            <el-option
              v-for="item in typeList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="商品二级分类" prop="clazzId2">
          <el-select v-model="params.clazzId2" multiple clearable>
            <el-option
              v-for="item in typeList2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </template>
    <div class="btnWrap">
      <div>
        <el-button @click="addItem" type="primary">加入购物车</el-button>
        <el-button @click="checkItem" type="primary">购物车</el-button>
        <el-button @click="confirmItem" type="primary">下单采购</el-button>
        <el-button @click="otherListInfo" type="primary">采购订单</el-button>
        <el-button
          v-if="!showTable"
          @click="allCheck"
          class="allCheck"
          type="primary"
          >全选</el-button
        >
      </div>

      <el-button @click="showTable = !showTable" type="text"
        >切换为{{ showTable ? '卡片' : '列表' }}显示</el-button
      >
    </div>
    <div v-show="!showTable" class="productWrap">
      <div
        class="commonItem"
        style="text-align: center; margin-bottom: 20px"
        v-for="(item, index) in productList"
        :key="index"
      >
        <el-tooltip placement="right">
          <div slot="content">
            <div>
              <span class="label">条形码</span>
              <span class="value">{{ item.barNo || '-' }}</span>
            </div>

            <div>
              <span class="label">颜色</span>
              <span class="value">{{ item.goodsColor || '-' }}</span>
            </div>

            <div>
              <span class="label">尺码</span>
              <span class="value">{{ item.goodsSize || '-' }}</span>
            </div>
          </div>
          <div @click="item.checked = !item.checked" class="specialInfo">
            <div class="content">
              <div class="leftInfo">
                <el-image
                  style="width: 130px; height: 120px"
                  :src="item.goodsImg"
                >
                </el-image>
                <div class="goodsName">{{ item.goodsName }}</div>
                <div class="goodsPrice">
                  <span style="font-weight: bold">采购金额：</span> ￥{{
                    (+item.purchasePrice * +item.goodsNum).toFixed(2) || 0
                  }}
                </div>
              </div>
              <div class="rightInfo">
                <p>
                  <span class="name">供应商：</span>
                  <span class="value">{{ item.supplierName || '-' }}</span>
                </p>
                <p>
                  <span class="name">品牌：</span>
                  <span class="value">{{ item.brandName || '-' }}</span>
                </p>
                <p>
                  <span class="name">采购单位：</span>
                  <span class="value">{{ item.purchaseUnit || '-' }}</span>
                </p>
                <p>
                  <span class="name">采购规格：</span>
                  <span class="value">{{
                    item.purchaseSpecification || '-'
                  }}</span>
                </p>
                <p>
                  <span class="name">采购单价：</span>
                  <span class="value"
                    >￥{{ item.purchasePriceStr || '-' }}</span
                  >
                </p>
                <p>
                  <span class="name">零售单位：</span>
                  <span class="value">{{ item.unit || '-' }}</span>
                </p>
                <p>
                  <span class="name">零售规格：</span>
                  <span class="value">{{ item.specification || '-' }}</span>
                </p>
                <p>
                  <span class="name">建议零售价：</span>
                  <span class="value"
                    >￥{{ item.suggestedRetailPrice || 0 }}</span
                  >
                </p>
              </div>
            </div>
            <div @click.stop style="display: flex; align-items: center">
              <el-input-number
                :min="item.purchaseMinNum"
                size="mini"
                v-model="item.goodsNum"
              ></el-input-number>
              &nbsp;&nbsp;{{ item.purchaseUnit }}
            </div>
          </div>
        </el-tooltip>

        <el-checkbox v-model="item.checked" />
      </div>
      <div
        v-if="!noLoad"
        style="width: 100%"
        v-loading="true"
        element-loading-text=""
        element-loading-spinner="el-icon-loading"
        element-loading-color="#000"
      ></div>
    </div>

    <div v-if="showTable" class="table-wrap">
      <el-table
        :data="productList"
        border
        @selection-change="selectHandleTable"
        height="100%"
        style="width: 100%"
      >
        <el-table-column
          show-overflow-tooltip
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="supplierName"
          label="供应商"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        ></el-table-column>
        <el-table-column label="商品图片">
          <template #default="{ row }">
            <el-popover
              v-if="row.goodsImg"
              effect="light"
              trigger="hover"
              placement="top"
              width="320px"
            >
              <template #default>
                <el-image
                  style="width: 300px; height: 300px"
                  :src="row.goodsImg"
                  fit="scale-down"
                ></el-image>
              </template>
              <template #reference>
                <el-image
                  style="height: 50px"
                  :src="row.goodsImg"
                  fit="scale-down"
                  :preview-src-list="[row.goodsImg]"
                ></el-image>
              </template>
            </el-popover>

            <el-image
              v-else
              :src="row.goodsImg"
              class="table-image"
              style="width: 50px; height: 50px"
            >
              <div slot="error" class="image-slot">
                <i
                  style="font-size: 50px !important"
                  class="el-icon-picture-outline"
                ></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="purchaseSpecification"
          label="采购规格"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseUnit"
          label="采购单位"
        ></el-table-column>
        <el-table-column show-overflow-tooltip label="采购单价">
          <template slot-scope="{ row }">
            <span>￥{{ row.purchasePriceStr || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="建议零售价">
          <template slot-scope="{ row }">
            <span>￥{{ row.suggestedRetailPrice || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="采购金额">
          <template slot-scope="{ row }">
            <span
              >￥{{
                (+row.purchasePrice * +row.goodsNum).toFixed(2) || 0
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="采购数量" width="180">
          <template slot-scope="{ row }">
            <div style="display: flex">
              <el-input-number
                :min="row.purchaseMinNum"
                size="mini"
                v-model="row.goodsNum"
              >
              </el-input-number>
              <span>&nbsp;&nbsp;{{ row.purchaseUnit }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button @click="tableProductDetail(row)" type="text"
              >商品详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <DetailModal
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :currentItem="currentItem"
    />

    <ConfirmOrderModal
      v-if="confirmOrderVisible"
      :visible.sync="confirmOrderVisible"
      :directOrder="directOrder"
      :editOrderPurchaseNo="editOrderPurchaseNo"
      :detailDataEdit="detailData"
      :targetSupplier="targetSupplier"
      :dataFormEdit="dataForm"
      @getData="getData(true)"
    />

    <OrderListModal
      v-if="orderListVisible"
      :visible.sync="orderListVisible"
      :currentItem="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './components/DetailModal'
import ConfirmOrderModal from './components/ConfirmOrderModal'
import OrderListModal from './components/OrderList/index'

const cardFreePaymentRules = {
  holderName: [
    {
      required: true,
      message: '请输入姓名'
    }
  ],
  idNo: [
    {
      required: true,
      message: '请输入身份证'
    }
  ],
  bankCardNo: [
    {
      required: true,
      message: '请输入银行卡号'
    }
  ],
  phone: [
    {
      required: true,
      message: '请输入预留手机号'
    }
  ],
  verifyCode: [
    {
      required: true,
      message: '请输入短信验证码'
    }
  ]
}

export default {
  mixins: [watchParamsGetData],
  components: {
    DetailModal,
    ConfirmOrderModal,
    // LogisticsModal,
    OrderListModal
  },
  data() {
    return {
      loading: false,
      allCheckFlag: false,
      detailVisible: false,
      confirmOrderVisible: false,
      logInfoVisible: false,
      orderListVisible: false,
      turnoverVisible: false,
      directOrder: false,
      editOrderPurchaseNo: null,
      cardFreePaymentRules,
      currentItem: null,
      showTable: true,
      tableData: [],
      selectionsTable: [],
      productList: [],
      brandList: [],
      typeList1: [],
      typeList2: [],
      supplierList: [],
      targetSupplier: [],
      detailData: null,
      dataForm: null,
      artEntity: {},
      deliveryDesc: {},
      params: {
        clazzId1: '',
        clazzId2: [],
        goodsName: '',
        barNo: '',
        limit: 20,
        page: 1
      },
      totalCount: 0,
      noLoad: false
    }
  },
  computed: {
    checkedData() {
      if (this.showTable) {
        return this.selectionsTable
      } else {
        return this.productList.filter(_ => _.checked)
      }
    }
  },
  watch: {
    showTable(val) {
      this.params.page = 1
      this.params.limit = 20
      this.getData()

      if (!val) {
        this.$nextTick(() => {
          document.querySelector('.productWrap').scrollTop = 0
        })
      }
    }
  },
  mounted() {
    const _this = this
    const el = document.querySelector('.productWrap')

    if (el) {
      el.addEventListener('scroll', function () {
        if (
          el.scrollHeight - el.clientHeight - el.scrollTop <= 0 &&
          !_this.noLoad
        ) {
          _this.params.page = _this.params.page + 1

          _this.debounce(_this.getData(), 300)
        }
      })
    }
  },
  created() {
    // this.getSuppliers()
    this.getTypeList()
    this.getBrandList()
    this.getData()
  },
  methods: {
    selectHandleTable(val) {
      this.selectionsTable = val
    },
    async confirmItem() {
      if (!this.checkedData.length) {
        this.$message.warning('请先选择商品！')
        return
      }

      const arr = []

      const tipsArr = []

      this.checkedData.forEach(item => {
        const tips = this.deliveryDesc[item.supplierId]

        if (tips && !tipsArr.includes(tips)) {
          tipsArr.push(tips)
          arr.push({
            name: item.supplierName,
            tips: tips
          })
        }
      })

      this.targetSupplier = arr

      this.dataForm = {}
      this.detailData = this.checkedData
      this.dataForm.storeId = this.cartEntity.storeId
      this.dataForm.cargoReceiver = this.cartEntity.cargoReceiver
      this.dataForm.phone = this.cartEntity.phone
      this.dataForm.remark = ''

      if (this.cartEntity.provinceId) {
        this.dataForm.storeAddress = [
          this.cartEntity.provinceId,
          this.cartEntity.cityId,
          this.cartEntity.regionId
        ]
      }

      this.dataForm.address = this.cartEntity.address

      this.editOrderPurchaseNo = null
      this.directOrder = true
      this.confirmOrderVisible = true
    },

    debounce(fn, delay) {
      var timer
      return function () {
        var context = this
        var args = arguments
        clearTimeout(timer)
        timer = setTimeout(function () {
          fn.apply(context, args)
        }, delay)
      }
    },
    tableProductDetail(row) {
      this.currentItem = row
      this.detailVisible = true
    },
    allCheck() {
      this.allCheckFlag = !this.allCheckFlag
      this.productList = this.productList.map(item => {
        item.checked = this.allCheckFlag
        return item
      })
    },
    async addItem() {
      if (this.loading) return

      if (!this.checkedData.length) {
        this.$message.warning('请先选择商品！')
        return
      }
      this.loading = true

      const sendData = this.checkedData.map(item => {
        return {
          barNo: item.barNo,
          goodsNum: item.goodsNum,
          supplierId: item.supplierId,
          middlemanId: item.middlemanId
        }
      })

      try {
        this.loading = true

        await this.$api.productPurchase.addStoreSoppingCart(sendData)
        this.$message.success('已加入采购库')
        this.getData(true)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    checkItem() {
      this.directOrder = false
      this.editOrderPurchaseNo = null
      this.detailData = null
      this.dataForm = null
      this.confirmOrderVisible = true
    },

    otherListInfo() {
      this.orderListVisible = true
    },
    async getSuppliers() {
      try {
        const res = await this.$api.common.getSuppliers({
          limit: 1000,
          page: 1
        })
        this.supplierList = (res.page.list || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {}
    },
    async getTypeList() {
      try {
        const res = await this.$api.classification.getClazzList({
          limit: 1000,
          page: 1
        })
        this.typeList1 = (res.clazzList.list || []).map(item => {
          return {
            label: item.clazzName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getTypeList()
        // this.typeList1 = (res.list || []).map(item => {
        //   return {
        //     label: item.clazzName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },

    async getBrandList() {
      try {
        const res = await this.$api.brandList.getGoodsBrandList({
          limit: 1000,
          page: 1
        })

        this.brandList = (res.brandList.list || []).map(item => {
          return {
            label: item.brandName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getBrandList()
        // this.brandList = (res.list || []).map(item => {
        //   return {
        //     label: item.brandName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },
    async getData(query) {
      this.loading = true

      const sendData = JSON.parse(JSON.stringify(this.params))

      if (query) {
        sendData.page = 1
        sendData.limit = 20
      }

      try {
        const res = await this.$api.productPurchase.getProductList(sendData)
        this.cartEntity = res.cartEntity || {}

        this.deliveryDesc = res.deliveryDesc || {}

        const productList = (res.page.list || []).map(item => {
          const unit = item.unit
          item.goodsNum = parseInt(item.goodsNum || item.purchaseMinNum || 1)
          item.goodsImg = this.$utils.getImg(item.goodsImg)
          item.purchasePriceStr =
            item.purchasePrice.toFixed(2) + (unit ? `/${unit}` : '')
          item.suggestedRetailPrice = item.suggestedRetailPrice.toFixed(2)
          item.purchasePrice = (
            item.purchasePrice * (item.conversionRatio || 1)
          ).toFixed(2)
          item.checked = false
          return item
        })

        if (this.showTable) {
          this.productList = productList
          this.totalCount = res.page.totalCount
        } else {
          this.noLoad = productList.length < this.params.limit

          if (this.params.page === 1) {
            this.productList = productList
          } else {
            this.productList = this.productList.concat(productList)
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.productWrap {
  margin-top: 10px;
  overflow: auto;
  min-height: 256px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  max-height: 72vh;
}

.productWrap .commonItem:hover .specialInfo {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 50%);
}

.productWrap .btnWrap {
  display: flex;
}

.specialInfo {
  width: 420px;
  word-break: break-word;
  border: 1px solid #ccc;
  padding-top: 10px;
  margin-bottom: 5px;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.specialInfo .content {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.specialInfo .detailName {
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
  max-width: 130px;
}

.specialInfo .content .rightInfo p {
  display: flex;
  align-items: center;
  margin: 0 0 7px !important;
}

.specialInfo .content .rightInfo p .name {
  font-weight: bold;
}

.specialInfo .content .rightInfo p .value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

.productWrap .goodsName {
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
}

.productWrap .goodsPrice {
  text-align: center;
  margin-bottom: 6px;
}

.productWrap .el-checkbox__inner {
  width: 20px;
  height: 20px;
  border: 2px solid #3c8dbc;
}

.productWrap .el-checkbox__inner::after {
  height: 10px;
  left: 6px;
}
</style>
