<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="余额支付"
    :visible.sync="show"
    width="600px"
  >
    <div style="margin-bottom: 20px">
      使用门店在供应商内的余额进行支付
      <b class="payTxt">￥{{ payOrderObj.totalPrice }}</b
      >，请输入余额支付密码完成支付。
    </div>

    <el-form ref="form" label-width="100px" :rules="rules" :model="form">
      <el-form-item label="支付密码：" prop="password">
        <el-input
          style="width: 250px"
          type="password"
          placeholder="请输入支付密码"
          show-password
          v-model.trim="form.password"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="balancePayHandler" :loading="btnLoading"
        >确定</el-button
      >
    </span>

    <AmountNotEnough :visible.sync="amountVisible" v-if="amountVisible" />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import AmountNotEnough from './AmountNotEnough'

export default {
  mixins: [dialogCommonParams],
  props: ['payRemark', 'payOrderObj'],
  data() {
    return {
      rules: {
        password: [
          {
            required: true,
            message: '请输入支付密码'
          }
        ]
      },
      amountVisible: false,
      loading: false,
      btnLoading: false,
      form: {
        password: ''
      },
      tableData: []
    }
  },
  created() {
    // this.getData()
  },
  components: {
    AmountNotEnough
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const r = await this.$api.productPurchase.payOffLineList()
        this.tableData = r.page.list
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    balancePayHandler() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.btnLoading = true

          try {
            const sendData = {
              payRemark: this.payRemark,
              purchaseNo: this.payOrderObj.purchaseNo,
              password: this.form.password
            }

            const res = await this.$api.productPurchase.balancePayHandler(
              sendData
            )

            if (res.code == 0) {
              this.$message.success('支付成功')
              this.show = false
              this.$emit('emitHandler')
            } else if (res.code == 1002) {
              this.amountVisible = true
            } else {
              this.$message.error(res.msg)
            }
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.payTxt {
  font-size: 20px;
  color: red;
}
</style>
