<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="confirmDetailflag ? '收货详情' : '确认收货'"
    :visible.sync="show"
    width="1200px"
  >
    <el-table
      border
      :height="300"
      :data="productDetailData"
      size="small"
      v-loading="loading"
      style="width: 100%"
    >
      <el-table-column show-overflow-tooltip prop="brandName" label="品牌">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsName" label="商品名称">
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        width="100"
        prop="barNo"
        label="条形码"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseUnit"
        label="采购单位"
      >
        <template slot-scope="{ row }">
          <span>{{ row.purchaseUnit || row.unit }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="规格">
        <template slot-scope="{ row }">
          <span>{{ row.specification || row.purchaseSpecification }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        prop="inPrice"
        label="采购单价(元)"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsNum"
        label="采购数量"
      ></el-table-column>
      <el-table-column width="110" label="发货数量">
        <template slot-scope="{ row }">
          <span>{{ row.merchantDeliverNum || row.receiveNum }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip width="155" label="门店收货数量">
        <template slot-scope="{ row }">
          <span v-if="confirmDetailflag">{{ row.storeConfirmNum || '-' }}</span>
          <el-input-number
            v-else
            :min="1"
            :max="row.merchantDeliverNum || row.receiveNum || 1"
            size="mini"
            v-model="row.storeConfirmNum"
          >
          </el-input-number>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip width="100" label="采购差价(元)">
        <template slot-scope="{ row }">
          <span v-if="row.storeConfirmNum">{{
            Number(
              (row.inPrice || 0) *
                (Number(row.goodsNum) - Number(row.storeConfirmNum) || 0)
            ).toFixed(2)
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        v-if="!confirmDetailflag"
        label="保质期(天)"
      >
        <template slot-scope="{ row }">
          <el-input
            v-model="row.guaranteePeriod"
            placeholder=""
            size="mini"
            @input="
              row.guaranteePeriod = row.guaranteePeriod.replace(/[^0-9]/g, '')
            "
          ></el-input>
        </template>
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button
        v-if="!confirmDetailflag"
        :disabled="!productDetailData.length"
        :loading="btnLoading"
        type="primary"
        @click="confirmSendProduct"
      >
        确认收货
      </el-button>
      <el-button
        :disabled="!productDetailData.length"
        :loading="btnLoading"
        type="primary"
        @click="downloadOrder"
      >
        下载收货单
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: [
    'confirmDetailflag',
    'purchaseNo',
    'purchaseId',
    'conversionRatio',
    'isChild'
  ],
  data() {
    return {
      loading: false,
      btnLoading: false,
      productDetailData: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true

      const sendData = { purchaseNo: this.purchaseNo }

      try {
        const r = this.isChild
          ? await this.$api.productPurchase.getChildOrderDetail(sendData)
          : await this.$api.productPurchase.confirmProductData(sendData)
        this.productDetailData =
          (r.detailList || []).map(item => {
            item.storeConfirmNum = this.confirmDetailflag
              ? item.storeConfirmNum
              : item.storeConfirmNum ||
                item.merchantDeliverNum ||
                item.receiveNum ||
                1
            return item
          }) || []
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    confirmSendProduct() {
      this.$confirm(
        '请再次确认采购商品均已收到，一旦操作将不可进行逆转！',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(async () => {
        try {
          this.btnLoading = true

          let targetData = JSON.parse(JSON.stringify(this.productDetailData))

          targetData.forEach(item => {
            item.goodsNum = item.storeConfirmNum
          })

          await this.$api.productPurchase.confirmSendProduct({
            purchaseNo: this.purchaseNo,
            goodsNumReqList: targetData,
            conversionRatio: this.conversionRatio
          })

          this.$message.success('确认收货成功！')
          this.show = false
          this.$emit('getData')
        } catch (e) {
          console.log(e)
        } finally {
          this.btnLoading = false
        }
      })
    },
    downloadOrder() {
      this.$emit('downloadOrder', this.purchaseNo)
    }
  }
}
</script>
<style lang="scss" scoped>
.btnWrap {
  display: flex;
  justify-content: space-between;
}
</style>
