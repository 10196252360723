<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="工行卡支付"
    :visible.sync="show"
    width="550px"
  >
    <el-form
      size="small"
      :model="cardFreePaymentForm"
      :rules="cardFreePaymentRules"
      ref="cardFreePaymentForm"
      label-width="100px"
    >
      <p>请输入你的身份信息，仅支持本人卡号</p>
      <el-form-item label="姓名" prop="holderName">
        <el-input
          v-model="cardFreePaymentForm.holderName"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idNo">
        <el-input
          v-model="cardFreePaymentForm.idNo"
          placeholder="请输入身份证号"
        ></el-input>
      </el-form-item>
      <p>请输入你的银行卡信息</p>
      <el-form-item label="银行卡号" prop="bankCardNo">
        <el-input
          v-model="cardFreePaymentForm.bankCardNo"
          placeholder="请输入银行卡号"
        ></el-input>
      </el-form-item>
      <el-form-item label="预留手机号" prop="phone">
        <el-input
          v-model="cardFreePaymentForm.phone"
          placeholder="请输入预留手机号"
        >
          <el-button
            slot="append"
            @click="getCodeHandler"
            type="primary"
            :disabled="!!numberTxt"
            >{{ currentNumTxt }}
          </el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="短信验证码" prop="verifyCode">
        <el-input
          v-model="cardFreePaymentForm.verifyCode"
          placeholder="请输入短信验证码"
        ></el-input>
      </el-form-item>
      <p>
        <span style="color: red">*</span
        >无卡支付仅支持使用中国工商银行卡进行绑定
      </p>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button
        type="primary"
        @click="cardFreePaymentFormSubmit"
        :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
const cardFreePaymentRules = {
  holderName: [
    {
      required: true,
      message: '请输入姓名'
    }
  ],
  idNo: [
    {
      required: true,
      message: '请输入身份证'
    }
  ],
  bankCardNo: [
    {
      required: true,
      message: '请输入银行卡号'
    }
  ],
  phone: [
    {
      required: true,
      message: '请输入预留手机号'
    }
  ],
  verifyCode: [
    {
      required: true,
      message: '请输入短信验证码'
    }
  ]
}
export default {
  mixins: [dialogCommonParams],
  props: ['employmentTypeList'],
  data() {
    return {
      cardFreePaymentRules,
      loading: false,
      codeTimer: null,
      numberTxt: '',
      cardFreePaymentForm: { amount: 1, idType: 0 }
    }
  },
  computed: {
    currentNumTxt() {
      return !this.numberTxt ? '获取验证码' : this.numberTxt + 's'
    }
  },
  methods: {
    getCodeHandler() {
      let n = 0
      this.$refs.cardFreePaymentForm.validateField(
        ['holderName', 'idNo', 'bankCardNo', 'phone'],
        valid => {
          if (!valid) {
            ++n
            if (n === 4) {
              if (this.codeTimer) return
              this.getSmsCode()
            }
          } else {
            console.log('验证失败')
          }
        }
      )
    },
    async getSmsCode() {
      try {
        const res = await this.$api.productPurchase.sendSMSCode(
          this.cardFreePaymentForm
        )

        this.$message.success('验证码已发送')
        this.cardFreePaymentForm.verifyCodeNo = res.verifyCodeNo

        this.numberTxt = 30
        this.codeTimer = setInterval(() => {
          this.numberTxt -= 1

          if (this.numberTxt <= 0) {
            clearInterval(this.codeTimer)
            this.codeTimer = null
            this.numberTxt = ''
          }
        }, 1000)
      } catch (e) {
        console.log(e)
        clearInterval(this.codeTimer)
        this.codeTimer = null
        this.numberTxt = ''
      }
    },
    cardFreePaymentFormSubmit() {
      this.$refs.cardFreePaymentForm.validate(async valid => {
        if (valid) {
          this.loading = true

          try {
            await this.$api.productPurchase.cardFreePaymentFormSubmit(
              this.cardFreePaymentForm
            )
            this.$message.success('签约成功')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        } else {
          console.log('验证失败')
        }
      })
    }
  }
}
</script>
