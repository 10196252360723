var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"append-to-body":"","destroy-on-close":true,"close-on-click-modal":false,"title":_vm.confirmDetailflag ? '收货详情' : '确认收货',"visible":_vm.show,"width":"1200px"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"border":"","height":300,"data":_vm.productDetailData,"size":"small"}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"brandName","label":"品牌"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"goodsName","label":"商品名称"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100","prop":"barNo","label":"条形码"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"purchaseUnit","label":"采购单位"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.purchaseUnit || row.unit))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"规格"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.specification || row.purchaseSpecification))])]}}])}),_c('el-table-column',{attrs:{"width":"100","prop":"inPrice","label":"采购单价(元)"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"goodsNum","label":"采购数量"}}),_c('el-table-column',{attrs:{"width":"110","label":"发货数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.merchantDeliverNum || row.receiveNum))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"155","label":"门店收货数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.confirmDetailflag)?_c('span',[_vm._v(_vm._s(row.storeConfirmNum || '-'))]):_c('el-input-number',{attrs:{"min":1,"max":row.merchantDeliverNum || row.receiveNum || 1,"size":"mini"},model:{value:(row.storeConfirmNum),callback:function ($$v) {_vm.$set(row, "storeConfirmNum", $$v)},expression:"row.storeConfirmNum"}})]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100","label":"采购差价(元)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.storeConfirmNum)?_c('span',[_vm._v(_vm._s(Number( (row.inPrice || 0) * (Number(row.goodsNum) - Number(row.storeConfirmNum) || 0) ).toFixed(2)))]):_c('span',[_vm._v("-")])]}}])}),(!_vm.confirmDetailflag)?_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"保质期(天)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"","size":"mini"},on:{"input":function($event){row.guaranteePeriod = row.guaranteePeriod.replace(/[^0-9]/g, '')}},model:{value:(row.guaranteePeriod),callback:function ($$v) {_vm.$set(row, "guaranteePeriod", $$v)},expression:"row.guaranteePeriod"}})]}}],null,false,3744224724)}):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取消")]),(!_vm.confirmDetailflag)?_c('el-button',{attrs:{"disabled":!_vm.productDetailData.length,"loading":_vm.btnLoading,"type":"primary"},on:{"click":_vm.confirmSendProduct}},[_vm._v(" 确认收货 ")]):_vm._e(),_c('el-button',{attrs:{"disabled":!_vm.productDetailData.length,"loading":_vm.btnLoading,"type":"primary"},on:{"click":_vm.downloadOrder}},[_vm._v(" 下载收货单 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }