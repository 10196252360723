<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="订单列表"
    :visible.sync="show"
    width="1300px"
    class="OrderList"
  >
    <div class="content">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="采购订单" name="first">
          <PurchaseOrderList v-if="activeName == 'first'" />
        </el-tab-pane>
        <el-tab-pane label="预订单列表" name="second">
          <PreOrderList v-if="activeName == 'second'" />
        </el-tab-pane>
        <el-tab-pane label="售后订单" name="third">
          <AfterOrderList v-if="activeName == 'third'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import PurchaseOrderList from './PurchaseOrderList'
import PreOrderList from './PreOrderList'
import AfterOrderList from './AfterOrderList'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      activeName: 'first',
      loading: false
    }
  },
  created() {},
  components: {
    PurchaseOrderList,
    PreOrderList,
    AfterOrderList
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.OrderList {
  ::v-deep .el-dialog {
    margin-top: 12vh !important;
  }
}
</style>
