<template>
  <el-dialog
    append-to-body
    class="confirmOrderModal"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="directOrder ? '确认下单' : '采购库'"
    :visible.sync="show"
    width="1200px"
  >
    <template>
      <el-divider content-position="left">订单类型</el-divider>
      <div style="margin-bottom: 16px">
        <el-radio
          style="margin-right: 0"
          border
          :disabled="editOrderPurchaseNo ? true : false"
          v-model="orderType"
          :label="1"
          >普通订单</el-radio
        >
        <el-radio
          border
          :disabled="editOrderPurchaseNo ? true : false"
          v-model="orderType"
          :label="2"
          >预定订单</el-radio
        >
      </div>
      <el-form
        :rules="rules"
        v-if="orderType == 2"
        :model="params"
        size="mini"
        ref="dataForm2"
        label-width="100px"
      >
        <el-form-item label="配送模式：">
          <el-select
            @change="orderTypeChange"
            style="width: 188px"
            v-model="params.orderType"
          >
            <el-option
              v-for="item in orderTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <template v-if="params.orderType == 1">
          <el-form-item label="预定时间：" prop="date">
            <el-date-picker
              style="width: 250px"
              v-model="params.date"
              :picker-options="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change="orderCountHandler"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="配送周期：" prop="distributionCycle">
            <el-input
              style="width: 188px"
              v-model="params.distributionCycle"
              @blur="orderCountHandler"
              @input="
                val => {
                  params.distributionCycle = onlyNum(val)
                }
              "
              placeholder="请填写周期天数"
            ></el-input
            >&nbsp;天送一次
          </el-form-item>
          <p v-if="+orderCount >= 1" class="tipsHeader">
            预定时间内共{{ orderCount }}笔订单
          </p>
        </template>

        <template v-else>
          <el-form-item label="预定时间：" prop="dateList">
            <el-date-picker
              type="dates"
              :picker-options="pickerOptions"
              v-model="params.dateList"
              placeholder="选择一个或多个日期"
            >
            </el-date-picker>
          </el-form-item>
          <p class="tipsHeader">
            预定时间内共{{ params.dateList.length }}笔订单
          </p>
        </template>
      </el-form>
    </template>

    <el-divider content-position="left">商品信息</el-divider>
    <template v-if="orderType == 2">
      <div
        v-for="(item, index) in targetSupplier"
        :key="index"
        class="supplierWrap"
      >
        <span>{{ item.name }}</span>
        <p class="tipsWrap">
          {{ item.tips }}
        </p>
      </div>
    </template>
    <div class="btnWrap">
      <el-button
        :disabled="!selections.length"
        @click="deleteItem(false)"
        type="danger"
        >批量删除
      </el-button>
    </div>

    <el-table
      border
      :height="260"
      :data="detailData"
      size="small"
      v-loading="loading"
      @selection-change="selectHandle"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsName"
        label="商品名称"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="barNo"
        label="条形码"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseUnit"
        label="采购单位"
      ></el-table-column>
      <el-table-column show-overflow-tooltip label="采购规格单价(元)">
        <template slot-scope="{ row }">
          <span>{{ row.purchasePrice }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip width="170" label="采购数量">
        <template slot-scope="{ row, $index }">
          <el-input-number
            @change="
              val => {
                goodsNumChange(
                  val,
                  row.cartDetailId,
                  row.purchaseMinNum,
                  $index,
                  row.supplierId,
                  row.middlemanId
                )
              }
            "
            size="mini"
            :min="1"
            v-model="row.goodsNum"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="金额小计(元)">
        <template slot-scope="{ row }">
          <span>{{
            Number((row.purchasePrice || 0) * (row.goodsNum || 0)).toFixed(2)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row, $index }">
          <el-button
            @click="
              deleteItem(
                row.cartDetailId || true,
                $index,
                row.supplierId,
                row.middlemanId
              )
            "
            size="mini"
            type="text"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-divider content-position="left">收货信息</el-divider>

    <el-form
      style="width: 900px"
      size="small"
      :rules="rules"
      :model="dataForm"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="收货人" prop="cargoReceiver">
        <el-input
          v-model="dataForm.cargoReceiver"
          placeholder="请输入收货人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone">
        <el-input
          v-model="dataForm.phone"
          placeholder="请输入联系方式"
        ></el-input>
      </el-form-item>

      <el-form-item label="收货地址" prop="storeAddress">
        <AreaSelect :value.sync="dataForm.storeAddress" />
      </el-form-item>

      <el-form-item label="详细地址" prop="address">
        <el-input
          v-model="dataForm.address"
          placeholder="请输入详细地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          placeholder="备注"
          v-model="dataForm.remark"
          maxlength="60"
          show-word-limit
        >
        </el-input>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" @click="saveAddress" :loading="isSaving"
          >更新收货信息</el-button
        >
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button
        v-if="orderType == 1"
        type="primary"
        @click="submitHandler(false)"
        :loading="isOrdering"
        >下单</el-button
      >
      <el-button
        v-else
        type="primary"
        @click="submitHandler(true)"
        :loading="isOrdering"
        >生成预订单</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: [
    'directOrder',
    'editOrderPurchaseNo',
    'detailDataEdit',
    'dataFormEdit',
    'targetSupplier'
  ],
  data() {
    const _this = this

    return {
      orderCount: 0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      params: {
        orderType: 1,
        date: [],
        dateList: [],
        distributionCycle: ''
      },
      tips: '(提示：在{配送时间}前完成支付，订单将在{当前日期+1天}进行配送，在{配送时间}后完成支付，订单将在{当前日期+2天}进行配送)',
      supllier: '南方乳业',
      showTips: true,
      orderType: 1,
      loading: false,
      isOrdering: false,
      supportPayOffline: '',
      purchasePaymentVisible: false,
      isSaving: false,
      rules: {
        cargoReceiver: [{ required: true, message: '请输入收货人' }],
        phone: [{ required: true, message: '请输入联系方式' }],
        storeAddress: [{ required: true, message: '请选择收货地址' }],
        address: [{ required: true, message: '请输入详细地址' }],
        date: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value || !value.length)
                return callback(new Error('请输入预定时间'))

              if (value < _this.days) {
                return callback(new Error('配送周期不能大于预定时间间隔'))
              }

              if (_this.params.distributionCycle) {
                _this.$refs.dataForm2.clearValidate()
              }

              callback()
            }
          }
        ],

        dateList: [
          { required: true, message: '请输入预定时间', trigger: 'blur' }
        ],

        distributionCycle: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('请输入配送周期'))

              if (value > _this.days) {
                return callback(new Error('配送周期不能大于预定时间间隔'))
              }

              if (_this.params.date.length) {
                _this.$refs.dataForm.clearValidate()
              }

              callback()
            }
          }
        ]
      },
      dataForm: {
        cargoReceiver: '',
        phone: '',
        storeAddress: [],
        address: '',
        remark: ''
      },
      orderTypeList: [
        {
          label: '周期配送',
          value: 1
        },
        {
          label: '自定义配送',
          value: 2
        }
      ],
      supplierList: [],
      selections: [],
      detailData: []
    }
  },
  created() {
    this.getDetailData()
  },
  computed: {
    days() {
      let targetDay = 0

      if (this.params.date.length) {
        targetDay = parseInt(
          (+new Date(this.params.date[1]) - +new Date(this.params.date[0])) /
            (24 * 3600 * 1000)
        )
      }

      return targetDay
    }
  },
  methods: {
    orderTypeChange(val) {},
    orderCountHandler() {
      if (
        this.params.date.length &&
        this.params.distributionCycle &&
        +this.days >= +this.params.distributionCycle
      ) {
        this.orderCount =
          Math.floor(+this.days / +this.params.distributionCycle) + 1
      } else {
        this.orderCount = 0
      }
    },
    closeHandler() {
      this.show = false
      this.$emit('getData')
    },
    async getDetailData() {
      this.loading = true

      const res = await this.$api.productPurchase.listStoreSoppingCart()

      try {
        if (this.detailDataEdit) {
          this.detailData = JSON.parse(JSON.stringify(this.detailDataEdit))
        } else {
          this.detailData = (res.list || []).map(item => {
            const unit = item.unit || ''
            item.goodsNum = parseInt(item.goodsNum || item.purchaseMinNum || 0)

            item.unitStr = unit
              ? (item.conversionRatio || 1) +
                unit +
                '/' +
                (item.purchaseUnit || unit)
              : '-'

            item.purchasePrice = (
              item.purchasePrice * (item.conversionRatio || 1)
            ).toFixed(2)
            return item
          })
        }

        this.dataForm.storeId = res.cartEntity.storeId
        this.dataForm.cargoReceiver = res.cartEntity.cargoReceiver
        this.dataForm.phone = res.cartEntity.phone

        if (res.cartEntity.provinceId) {
          this.dataForm.storeAddress = [
            res.cartEntity.provinceId,
            res.cartEntity.cityId,
            res.cartEntity.regionId
          ]
        }

        this.dataForm.address = res.cartEntity.address
        this.dataForm.remark = ''
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    selectHandle(val) {
      this.selections = val
    },
    deleteItem(id, index, supplierId, middlemanId) {
      this.$confirm(
        index ? '是否要删除该商品?' : '是否要删除所选商品吗?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(async () => {
        if (this.directOrder) {
          if (id) {
            this.detailData.splice(index, 1)
          } else {
            this.selections.forEach(item => {
              for (var i = 0; i < this.detailData.length; i++) {
                if (this.detailData[i].id == item.id) {
                  this.detailData.splice(i, 1)
                  i = i - 1
                }
              }
            })
          }
          return
        }

        this.loading = true

        let sendData = [
          {
            supplierId: supplierId || undefined,
            middlemanId: middlemanId || undefined,
            cartDetailId: id
          }
        ]

        if (!id) {
          sendData = this.selections.map(_ => ({
            supplierId: _.supplierId || undefined,
            middlemanId: _.middlemanId || undefined,
            cartDetailId: _.cartDetailId
          }))
        }

        try {
          await this.$api.productPurchase.deleteStoreSoppingCart(sendData)

          this.$message.success('删除成功')
          this.getDetailData()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      })
    },
    goodsNumChange(
      goodsNum,
      cartDetailId,
      purchaseMinNum,
      index,
      supplierId,
      middlemanId
    ) {
      if (purchaseMinNum && goodsNum < purchaseMinNum) {
        this.detailData[index].goodsNum = 100
        this.$nextTick(() => {
          this.detailData[index].goodsNum = purchaseMinNum
        })

        return this.$message.warning(
          '采购数量最低为' + purchaseMinNum + '，如需删除请点击右侧删除按钮'
        )
      }
      if (!this.directOrder) {
        const sendData = this.detailData.map(item => {
          return {
            supplierId: supplierId,
            middlemanId: middlemanId,
            goodsNum: item.goodsNum,
            cartDetailId: item.cartDetailId
          }
        })

        sendData.find(_ => _.cartDetailId === cartDetailId).goodsNum = goodsNum

        this.$api.productPurchase.updateStoreSoppingCartNum(sendData)
      }
    },
    saveAddress() {
      this.$refs.dataForm.validate(async valid => {
        if (valid) {
          this.isSaving = true

          const { storeAddress, ...sendData } = this.dataForm

          if (storeAddress && storeAddress.length) {
            sendData.provinceId = storeAddress[0]
            sendData.cityId = storeAddress[1] || ''
            sendData.regionId = storeAddress[2] || ''
          }

          try {
            await this.$api.productPurchase.updateStoreSoppingCart(sendData)
            this.$message.success('更新成功')
          } catch (e) {
            console.log(e)
          } finally {
            this.isSaving = false
          }
        }
      })
    },
    async submitHandler(preOrder) {
      if (this.isOrdering) return

      const targetArr = this.selections

      if (!targetArr.length) {
        this.$message.warning('请选择要下单的商品')
        return
      }

      const sendData = {
        orderType: this.params.orderType,
        cargoReceiver: this.dataForm.cargoReceiver,
        phone: this.dataForm.phone,
        address: this.dataForm.address,
        remark: this.dataForm.remark,
        purchaseDetailList: targetArr.map(item => {
          return {
            goodsName: item.goodsName || '',
            inPrice: item.purchasePrice || 0,
            goodsNum: parseInt(item.goodsNum || 1),
            barNo: item.barNo,
            cartDetailId: item.cartDetailId,
            unit: item.unit,
            goodsId: item.id,
            supplierId: item.supplierId,
            middlemanId: item.middlemanId,
            serviceType: item.serviceType,
            isServiceProvider: item.isServiceProvider,
            serviceId: item.serviceId
          }
        })
      }

      if (this.dataForm.storeAddress && this.dataForm.storeAddress.length) {
        sendData.provinceId = this.dataForm.storeAddress[0]
        sendData.cityId = this.dataForm.storeAddress[1] || ''
        sendData.regionId = this.dataForm.storeAddress[2] || ''
      }

      if (preOrder) {
        let sTime = ''
        let eTime = ''
        let distributionCycle = ''

        if (this.params.orderType == 1) {
          sTime = this.params.date[0]
          eTime = this.params.date[1]
          distributionCycle = this.params.distributionCycle
        } else {
          const targetArray = this.params.dateList.map(_ => +new Date(_)).sort()

          sTime = this.$utils.dateFormat(targetArray[0], 'YYYY-MM-DD HH:mm:ss')
          eTime = this.$utils.dateFormat(
            targetArray[targetArray.length - 1],
            'YYYY-MM-DD HH:mm:ss'
          )
          distributionCycle = undefined
        }

        sendData.dateList = this.params.dateList.map(item =>
          this.$utils.dateFormat(item, 'YYYY-MM-DD HH:mm:ss')
        )

        sendData.startTime = this.$utils.dateFormat(
          sTime,
          'YYYY-MM-DD HH:mm:ss'
        )
        sendData.endTime = this.$utils.dateFormat(eTime, 'YYYY-MM-DD HH:mm:ss')

        sendData.distributionCycle = distributionCycle
      }

      if (this.editOrderPurchaseNo) {
        sendData.purchaseNo = this.editOrderPurchaseNo
      }

      this.$refs.dataForm.validate(async valid => {
        if (valid) {
          this.isOrdering = true

          let res = {}

          try {
            if (preOrder) {
              await this.$refs.dataForm2.validate()
              res = !this.directOrder
                ? await this.$api.productPurchase.createdPreOrderCart(sendData)
                : await this.$api.productPurchase.createdPreOrder(sendData)
              this.$message.success('预定单已生成，可前往采购订单内进行查看')
            } else {
              res = this.directOrder
                ? this.editOrderPurchaseNo
                  ? await this.$api.productPurchase.confirmUpdatePurchase(
                      sendData
                    )
                  : await this.$api.productPurchase.confirmSave(sendData)
                : await this.$api.productPurchase.confirmCartSave(sendData)

              if (this.editOrderPurchaseNo) {
                this.$message({
                  message: '修改成功',
                  type: 'success'
                })
              } else {
                this.$alert(
                  `当前订单已生成，订单编号为${res.purchaseNo.join(
                    '、'
                  )}，可前往采购订单内进行查看`,
                  '提示',
                  {
                    confirmButtonText: '确定',
                    callback: action => {}
                  }
                )
              }
            }

            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.isOrdering = false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss">
.confirmOrderModal {
  .el-dialog {
    margin-top: 5vh !important;
  }
  .el-dialog__body {
    padding: 0 20px !important;
  }
  .el-dialog .el-form-item {
    margin-bottom: 14px !important;
  }
  .supplierWrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .item {
      margin-left: 10px;
    }

    .tipsWrap {
      margin-left: 20px;
      color: #999;
    }
  }
  .tipsHeader {
    margin: 10px 0;
    color: #999;
    text-indent: 16px;
  }
}
</style>
