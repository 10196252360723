<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="发货信息"
    :visible.sync="show"
    width="400px"
    v-loading="loading"
  >
    <el-form size="small" label-width="100px">
      <el-form-item label="订单编号：">
        <span>{{ logInfoForm.purchaseNo }}</span>
      </el-form-item>

      <el-form-item label="配送方式：">
        <span>{{ logInfoForm.typeStr }}</span>
      </el-form-item>

      <template v-if="+logInfoForm.type === 1">
        <el-form-item label="车牌号：">
          <span>{{ logInfoForm.carNo }}</span>
        </el-form-item>

        <el-form-item label="联系电话：">
          <span>{{ logInfoForm.phone }}</span>
        </el-form-item>
      </template>

      <template v-if="+logInfoForm.type === 2">
        <el-form-item label="快递公司：">
          <span>{{ logInfoForm.logisticsCompany }}</span>
        </el-form-item>
        <el-form-item label="联系电话：">
          <span>{{ logInfoForm.phone }}</span>
        </el-form-item>
      </template>

      <template v-if="logInfoForm.type === 3">
        <el-form-item label="快递公司：">
          <span>{{ logInfoForm.logisticsCompany }}</span>
        </el-form-item>

        <el-form-item label="快递编号：">
          <span>{{ logInfoForm.logisticsNo }}</span>
        </el-form-item>
      </template>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['purchaseNo'],
  data() {
    return {
      loading: false,
      logInfoForm: {
        purchaseNo: '',
        type: '',
        logisticsCompany: '',
        logisticsNo: '',
        carNo: '',
        phone: ''
      }
    }
  },
  created() {
    this.getLogInfo()
  },
  methods: {
    async getLogInfo() {
      try {
        this.loading = true
        this.logInfoForm.purchaseNo = this.purchaseNo

        const res = await this.$api.productPurchase.getLogInfo({
          purchaseNo: this.purchaseNo
        })

        if (res.purchaseLogistics) {
          res.purchaseLogistics.typeStr =
            +res.purchaseLogistics.type === 3
              ? '快递配送'
              : +res.purchaseLogistics.type === 2
              ? '零单物流'
              : +res.purchaseLogistics.type === 1
              ? '整车物流'
              : '-'
          this.logInfoForm = res.purchaseLogistics
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
