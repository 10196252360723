<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="线下支付"
    :visible.sync="show"
    width="600px"
  >
    <div style="margin-bottom: 20px">
      使用线下支付，您需要通过线下转账的方式支付
      <b class="payTxt">￥{{ payOrderObj.totalPrice }}</b
      >元，点击确定将完成下单。
    </div>

    <!-- <el-table
      v-loading="loading"
      :data="tableData"
      max-height="300"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        prop="supplierName"
        label="供应商名称"
      >
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="phone" label="联系方式">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="goods" label="供应商品">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="payAmount" label="付款金额">
      </el-table-column>
    </el-table> -->

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="payOfflineHandler" :loading="btnLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['payRemark', 'payOrderObj'],
  data() {
    return {
      loading: false,
      btnLoading: false,
      tableData: []
    }
  },
  created() {
    // this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const r = await this.$api.productPurchase.payOffLineList()
        this.tableData = r.page.list
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async payOfflineHandler() {
      this.btnLoading = true

      try {
        const sendData = {
          payRemark: this.payRemark,
          purchaseNo: this.payOrderObj.purchaseNo
        }

        await this.$api.productPurchase.payOfflineHandler(sendData)

        this.$message.success('支付成功')
        this.show = false
        this.$emit('emitHandler')
      } catch (e) {
        console.log(e)
      } finally {
        this.btnLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.payTxt {
  font-size: 20px;
  color: red;
}
</style>
