<template>
  <div class="purchaseModal">
    <div class="formWrap">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item prop="purchaseNo" label="采购单号">
          <el-input placeholder="采购单号" v-model="params.purchaseNo" />
        </el-form-item>
        <el-form-item prop="operator" label="制单人">
          <el-input placeholder="制单人" v-model="params.operator" />
        </el-form-item>

        <el-form-item label="采购日期" prop="daterange">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="status" label="采购状态">
          <el-select placeholder="采购状态" clearable v-model="params.status">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="payStatus" label="付款状态">
          <el-select
            placeholder="付款状态"
            clearable
            v-model="params.payStatus"
          >
            <el-option
              v-for="item in payList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-button type="primary" plain @click="getData(true)">
          查询
        </el-button>
        <el-button plain @click="parentClear">重置</el-button>
      </el-form>
    </div>

    <div>
      <el-table
        @expand-change="expandChange"
        :row-key="getRowKeys"
        :expand-row-keys="expands"
        :data="logisticsData"
        v-loading="loading"
        :height="500"
        border
      >
        <el-table-column
          show-overflow-tooltip
          prop="purchaseNo"
          label="采购单号"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="orderTimeStr"
          label="采购日期"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="operator"
          label="制单人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsNum"
          label="总数量"
        ></el-table-column>
        <el-table-column
          width="100"
          prop="payPrice"
          label="采购金额(元)"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="statusStr"
          label="采购状态"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="payStatusStr"
          label="付款状态"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="payTimeStr"
          label="付款日期"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="arrivalTime"
          label="到货日期"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="remark"
          label="备注"
        ></el-table-column>
        <el-table-column width="370" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.nextPurchase == 1"
              type="text"
              size="mini"
              @click="toggleRowExpansion(row)"
              >{{
                row.showChildList ? '收起子订单列表' : '子订单列表'
              }}</el-button
            >

            <el-button
              v-if="
                row.status == 3 && row.payStatus == 1 && row.nextPurchase != 1
              "
              @click="logInfo(row.purchaseNo)"
              size="mini"
              type="text"
              >物流信息</el-button
            >
            <el-button
              @click="productDetail(row.purchaseNo, row.id, row, null)"
              size="mini"
              type="text"
              >采购明细</el-button
            >

            <template v-if="row.payStatus == 2">
              <el-button
                v-if="
                  +row.payType !== 2 || (row.payType == 2 && row.status == 1)
                "
                @click="payHandler(row.purchaseNo, row.supportPayOffline)"
                size="mini"
                type="text"
                >付款
              </el-button>
            </template>

            <el-button
              v-if="
                row.status == 3 && row.payStatus == 1 && row.nextPurchase != 1
              "
              @click="confirmTrigger(row)"
              size="mini"
              type="text"
              >确认收货</el-button
            >

            <el-button
              v-if="row.status == 4"
              @click="confirmTrigger(row, 'true')"
              size="mini"
              type="text"
            >
              收货详情</el-button
            >

            <el-button
              v-if="row.payTime"
              @click="payRecord(row.purchaseNo)"
              size="mini"
              type="text"
            >
              付款记录</el-button
            >

            <el-button
              @click="downloadOrder(row.purchaseNo)"
              size="mini"
              type="text"
            >
              导出明细</el-button
            >

            <template v-if="row.nextPurchase != 1">
              <el-button
                v-if="row.status == 2"
                @click="applyRefund(row.purchaseNo)"
                size="mini"
                type="text"
              >
                申请退款</el-button
              >

              <el-button
                v-if="row.status == 3 || row.status == 4"
                @click="applyAfterSale(row.purchaseNo)"
                size="mini"
                type="text"
              >
                申请售后</el-button
              >
            </template>

            <el-button
              v-if="row.payStatus == 2 && row.payType != 2"
              @click="editOrder(row.purchaseNo, row.id, row)"
              size="mini"
              type="text"
            >
              修改</el-button
            >

            <el-button
              v-if="row.payStatus == 2 && row.status == 1"
              @click="deleteOrder(row.purchaseNo, row.id)"
              size="mini"
              type="text"
            >
              删除</el-button
            >
          </template>
        </el-table-column>

        <el-table-column width="1" type="expand">
          <template slot-scope="{ row }">
            <div class="expandWrap">
              <el-table
                v-loading="row.loading"
                size="small"
                :data="row.expandData"
                border
                max-height="300"
              >
                <el-table-column
                  show-overflow-tooltip
                  prop="purchaseNo"
                  label="采购单号"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="orderTimeStr"
                  label="采购日期"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="operator"
                  label="制单人"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="goodsNum"
                  label="总数量"
                ></el-table-column>
                <el-table-column
                  width="100"
                  prop="payPrice"
                  label="采购金额(元)"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="statusStr"
                  label="采购状态"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="payStatusStr"
                  label="付款状态"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="payTimeStr"
                  label="付款日期"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="arrivalTime"
                  label="到货日期"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="remark"
                  label="备注"
                ></el-table-column>
                <el-table-column width="200" label="操作">
                  <template slot-scope="{ row }">
                    <el-button
                      @click="productDetail(row.purchaseNo, row.id, row, true)"
                      size="mini"
                      type="text"
                      >采购明细</el-button
                    >

                    <el-button
                      v-if="row.status == 3 && row.payStatus == 1"
                      @click="logInfo(row.purchaseNo)"
                      size="mini"
                      type="text"
                      >物流信息</el-button
                    >

                    <el-button
                      v-if="row.status == 2"
                      @click="applyRefund(row.purchaseNo, true)"
                      size="mini"
                      type="text"
                    >
                      申请退款</el-button
                    >

                    <el-button
                      v-if="row.status == 3 || row.status == 4"
                      @click="applyAfterSale(row.purchaseNo, true)"
                      size="mini"
                      type="text"
                    >
                      申请售后</el-button
                    >

                    <el-button
                      v-if="row.status == 3 && row.payStatus == 1"
                      @click="confirmTrigger(row, false, true)"
                      size="mini"
                      type="text"
                      >确认收货</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        slot="pagination"
        :totalCount="totalCount"
        :page-size.sync="params.limit"
        :page.sync="params.page"
        @change="getData"
      />
    </div>

    <LogInfoModal
      v-if="logInfoVisible"
      :visible.sync="logInfoVisible"
      :purchaseNo="purchaseNo"
    />

    <ProductDetailModal
      v-if="productDetailVisible"
      :visible.sync="productDetailVisible"
      :currentProductDetail="currentProductDetail"
      :isChild="isChild"
    />

    <PurchasePaymentModal
      v-if="purchasePaymentVisible"
      :visible.sync="purchasePaymentVisible"
      :purchaseNo="purchaseNo"
      :supportPayOffline="supportPayOffline"
      @getData="getData(true)"
    />

    <ConfirmProductModal
      v-if="confirmProductVisible"
      :visible.sync="confirmProductVisible"
      :purchaseId="purchaseId"
      :purchaseNo="purchaseNo"
      :isChild="isChild"
      :conversionRatio="conversionRatio"
      :confirmDetailflag="confirmDetailflag"
      @getData="getData(true)"
      @downloadOrder="
        purchaseNo => {
          downloadOrder(purchaseNo)
        }
      "
    />

    <ConfirmOrderModal
      v-if="confirmOrderVisible"
      :visible.sync="confirmOrderVisible"
      :directOrder="true"
      :editOrderPurchaseNo="editOrderPurchaseNo"
      :detailDataEdit="detailData"
      :dataFormEdit="dataForm"
      @getData="getData(true)"
    />

    <PayRecordModal
      v-if="payRecordVisible"
      :visible.sync="payRecordVisible"
      :purchaseNo="purchaseNo"
    />

    <AfterApplyModal
      v-if="afterApplyVisible"
      :visible.sync="afterApplyVisible"
      @getData="getData(true)"
      :purchaseNo="purchaseNo"
      :isChild="isChild"
    />
  </div>
</template>

<script>
import LogInfoModal from '../LogInfoModal'
import ProductDetailModal from '../ProductDetailModal'
import PurchasePaymentModal from '../PurchasePaymentModal'
import ConfirmProductModal from '../ConfirmProductModal'
import PayRecordModal from '../PayRecordModal'
import ConfirmOrderModal from '../ConfirmOrderModal'
import AfterApplyModal from '../AfterApplyModal'
const statusList = [
  {
    label: '已取消',
    value: '0'
  },
  {
    label: '未下单',
    value: '1'
  },
  {
    label: '待配送',
    value: '2'
  },
  {
    label: '配送中',
    value: '3'
  },
  {
    label: '已完结',
    value: '4'
  }
]

const payList = [
  {
    label: '已付款',
    value: '1'
  },
  {
    label: '未付款',
    value: '2'
  }
]

const formatStatus = {
  0: '已取消',
  1: '未下单',
  2: '待配送',
  3: '配送中',
  4: '已完结'
}

export default {
  data() {
    return {
      editOrderPurchaseNo: null,
      detailData: null,
      dataForm: null,
      isChild: null,
      purchaseNo: null,
      supportPayOffline: false,
      purchaseId: null,
      loading: false,
      logInfoVisible: false,
      productDetailVisible: false,
      purchasePaymentVisible: false,
      confirmProductVisible: false,
      payRecordVisible: false,
      confirmOrderVisible: false,
      afterApplyVisible: false,
      currentProductDetail: {},
      totalCount: 0,
      daterange: '',
      statusList,
      payList,
      formatStatus,
      logisticsData: [],
      afterSaleGoodsDtoList: [],
      expands: [],
      params: {
        purchaseNo: '',
        operator: '',
        status: '',
        payStatus: '',
        beginDate: '',
        endDate: '',
        limit: 20,
        page: 1
      },
      confirmDetailflag: ''
    }
  },
  components: {
    LogInfoModal,
    ProductDetailModal,
    PurchasePaymentModal,
    ConfirmProductModal,
    PayRecordModal,
    ConfirmOrderModal,
    AfterApplyModal
  },
  created() {
    this.getData()
  },
  methods: {
    parentClear() {
      const filtersForm = this.$refs.filtersForm
      filtersForm && filtersForm.resetFields()
      this.getData(true)
    },
    async getDetail(purchaseNo, isChild) {
      try {
        const sendData = {
          purchaseNo
        }

        const r = isChild
          ? await this.$api.productPurchase.getChildOrderDetail(sendData)
          : await this.$api.productPurchase.getOrderDetail(sendData)

        this.afterSaleGoodsDtoList = (r.detailList || []).map(item => {
          item.applyNum = item.goodsNum
          return item
        })
      } catch (e) {
        console.log(e)
      }
    },
    applyRefund(purchaseNo, isChild) {
      this.$prompt('请输入退款备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        inputPattern: /\S+/,
        inputErrorMessage: '请输入退款备注'
      })
        .then(async ({ value }) => {
          this.loading = true
          try {
            await this.getDetail(purchaseNo, isChild)

            await this.$api.productPurchase.afterSell({
              type: 1,
              reason: value,
              orderId: purchaseNo,
              afterSaleGoodsDtoList: this.afterSaleGoodsDtoList.map(item => ({
                goodsId: item.goodsId,
                goodsName: item.goodsName,
                purchasePrice: item.inPrice,
                applyNum: item.applyNum
              }))
            })

            this.$message.success('操作成功！')
            this.getData(true)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },

    getRowKeys(row) {
      return row.id
    },

    expandChange(row, array) {
      if (array.length) {
        this.expands = [row.id]
        this.renderExpandChildData(row, 1, true)
      }
      row.showChildList = array.length
    },
    async renderExpandChildData(row) {
      try {
        row.loading = true

        const r = await this.$api.productPurchase.childOrderList({
          purchaseNo: row.id
        })

        row.expandData = (r.detailList || []).map(item => {
          item.orderTimeStr = this.$utils.dateFormat(
            item.orderTime,
            'YYYY-MM-DD'
          )
          item.statusStr = this.formatStatus[item.status]
          item.payStatusStr = +item.payStatus === 1 ? '已付款' : '未付款'
          item.payTimeStr = this.$utils.dateFormat(item.payTime, 'YYYY-MM-DD')
          item.arrivalTime = this.$utils.dateFormat(
            item.arrivalTime,
            'YYYY-MM-DD'
          )

          return item
        })
      } catch (e) {
        console.log(e)
      } finally {
        row.loading = false
      }
    },
    async toggleRowExpansion(row) {
      if (!row.showChildList) {
        this.logisticsData.forEach(_ => {
          _.showChildList = false
        })
      }

      row.showChildList = !row.showChildList

      if (!row.showChildList) {
        this.expands = []
      } else {
        this.expands = [row.id]
        this.renderExpandChildData(row, 1, true)
      }
    },
    payRecord(purchaseNo) {
      this.payRecordVisible = true
      this.purchaseNo = purchaseNo
    },
    downloadOrder(currentNo) {
      window.open(
        '/purchase/purchase-detail/export?purchaseNo=' +
          currentNo +
          '&token=' +
          localStorage.getItem('token'),
        'target'
      )
    },
    logInfo(purchaseNo) {
      this.purchaseNo = purchaseNo
      this.logInfoVisible = true
    },

    applyAfterSale(purchaseNo, isChild = null) {
      this.purchaseNo = purchaseNo
      this.isChild = isChild
      this.afterApplyVisible = true
    },

    productDetail(purchaseNo, purchaseId, row, isChild = null) {
      this.productDetailVisible = true
      this.currentProductDetail = row
      this.isChild = isChild
    },
    payHandler(pNo, supportPayOffline) {
      this.purchaseNo = pNo
      this.supportPayOffline = supportPayOffline == 1 ? true : false
      this.purchasePaymentVisible = true
    },
    confirmTrigger(row, confirmDetailflag, isChild = null) {
      this.purchaseNo = row.purchaseNo
      this.purchaseId = row.purchaseId
      this.conversionRatio = row.conversionRatio
      this.isChild = isChild
      this.confirmDetailflag = confirmDetailflag || ''
      this.confirmProductVisible = true
    },
    editOrder(purchaseNo, id, row) {
      this.$confirm(`请确认是否修改订单${purchaseNo}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loading = true

        try {
          const r = await this.$api.productPurchase.editOrder({
            purchaseNo: purchaseNo
          })

          const detailData = (r.detailList || []).map(item => {
            item.goodsNum = parseInt(item.goodsNum || item.purchaseMinNum || 0)
            item.purchaseUnit = item.unit
            item.id = item.goodsId
            item.purchasePrice = item.inPrice
            return item
          })

          const dataForm = {
            storeId: row.storeId,
            cargoReceiver: row.cargoReceiver,
            phone: row.phone,
            storeAddress: row.storeAddress,
            remark: row.remark
          }

          this.detailData = detailData
          this.dataForm = dataForm
          this.editOrderPurchaseNo = purchaseNo
          this.confirmOrderVisible = true
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      })
    },

    deleteOrder(purchaseNo, id) {
      this.$confirm(`请确认是否删除订单${purchaseNo}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await this.$api.productPurchase.deleteOrder({
            purchaseId: id
          })
          this.$message.success('删除成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
        }
      })
    },
    async getData(query) {
      this.loading = true

      if (query) {
        this.params.limit = 20
        this.params.page = 1
      }

      if (this.daterange) {
        this.params.beginDate = this.$utils.dateFormat(
          this.daterange[0],
          'YYYY-MM-DD'
        )
        this.params.endDate = this.$utils.dateFormat(
          this.daterange[1],
          'YYYY-MM-DD'
        )
      } else {
        this.params.beginDate = ''
        this.params.endDate = ''
      }

      try {
        const r = await this.$api.productPurchase.getLogisticsData(this.params)
        this.logisticsData = ((r.page && r.page.list) || []).map(item => {
          item.orderTimeStr = this.$utils.dateFormat(
            item.createTime,
            'YYYY-MM-DD HH:mm:ss'
          )
          item.statusStr = this.formatStatus[item.status]
          item.payStatusStr = +item.payStatus === 1 ? '已付款' : '未付款'
          item.payTimeStr = this.$utils.dateFormat(
            item.payTime,
            'YYYY-MM-DD HH:mm:ss'
          )
          item.arrivalTime = this.$utils.dateFormat(
            item.arrivalTime,
            'YYYY-MM-DD HH:mm:ss'
          )

          item.expandData = []
          item.loading = false

          return item
        })

        this.totalCount = (r.page && r.page.totalCount) || 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.purchaseModal {
  .el-form-item {
    margin-bottom: 5px !important;
  }
  .pagination-wrap {
    justify-content: center !important;
  }
  .expandWrap {
    margin: 10px;

    ::v-deep .el-table th,
    .el-table tr {
      background-color: #f5f7fa;
    }
  }
  ::v-deep .el-table__expand-column {
    display: none !important;
  }
  .formWrap {
    height: 69px;
    margin-bottom: 10px;
  }
}
</style>
