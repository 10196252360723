<template>
  <div class="purchaseModal">
    <div class="formWrap">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="售后单号" prop="id">
          <el-input v-model="params.id" placeholder="售后单号" />
        </el-form-item>
        <el-form-item label="支付单号" prop="orderId">
          <el-input v-model="params.orderId" placeholder="支付单号" />
        </el-form-item>
        <el-form-item label="申请时间" prop="date">
          <el-date-picker
            v-model="params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态" prop="storeState">
          <el-select v-model="params.storeState" clearable>
            <el-option
              v-for="item in storeStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="售后类型" prop="type">
          <el-select v-model="params.type" clearable>
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" plain @click="getData(true)">
          查询
        </el-button>
        <el-button plain @click="parentClear">重置</el-button>
      </el-form>
    </div>

    <div>
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        :height="500"
      >
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column show-overflow-tooltip prop="id" label="售后单号" />
        <el-table-column show-overflow-tooltip prop="typeStr" label="售后类型">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          min-width="130"
          prop="orderId"
          label="关联支付订单编号"
        />
        <el-table-column
          show-overflow-tooltip
          prop="statusStr"
          label="订单状态"
        />

        <el-table-column
          show-overflow-tooltip
          prop="applyTime"
          label="申请时间"
        />

        <el-table-column width="230" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.statusStr == '申请中'"
              type="text"
              @click="cancelHandler(row)"
              size="mini"
            >
              撤销申请
            </el-button>

            <el-button
              v-if="row.statusStr == '待退货'"
              type="text"
              @click="sendHandler(row, false)"
              size="mini"
            >
              填写物流
            </el-button>

            <template v-if="row.statusStr == '待收货'">
              <el-button @click="confirmHandler(row)" type="text" size="mini">
                确认收货
              </el-button>

              <!-- <el-button
                @click="sendHandler(row, true)"
                type="text"
                size="mini"
              >
                配送信息  
              </el-button> -->
            </template>

            <el-button @click="detailHandler(row)" type="text" size="mini">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        slot="pagination"
        :totalCount="totalCount"
        :page-size.sync="params.limit"
        :page.sync="params.page"
        @change="getData"
      />
    </div>

    <SendModal
      v-if="visible"
      :visible.sync="visible"
      :currentItem="currentItem"
      :isDetail="isDetail"
      @getData="getData(true)"
    />
    <AfterDetailModal
      v-if="detailVisible"
      :visible.sync="detailVisible"
      :currentItem="currentItem"
    />
  </div>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import SendModal from './components/SendModal.vue'
import AfterDetailModal from './components/AfterDetailModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      isDetail: false,
      visible: false,
      detailVisible: false,
      logisticsVisible: false,
      currentItem: null,
      loading: false,
      tableData: [],

      statusObj: {
        0: '申请中',
        1: '待退货',
        2: '退货中',
        3: '待发货',
        4: '待收货',
        5: '已完成',
        6: '已拒绝',
        7: '已撤销'
      },

      typeObj: {
        3: '换货',
        1: '退款',
        2: '退货退款',
        4: '订单取消'
      },

      storeStateList: [
        {
          label: '申请中',
          value: '0'
        },
        {
          label: '待退货',
          value: '1'
        },
        {
          label: '退货中',
          value: '2'
        },
        {
          label: '待发货',
          value: '3'
        },
        {
          label: '待收货',
          value: '4'
        },
        {
          label: '已完成',
          value: '5'
        },
        {
          label: '已拒绝',
          value: '6'
        },
        {
          label: '已撤销',
          value: '7'
        }
      ],
      typeList: [
        {
          label: '换货',
          value: '3'
        },
        {
          label: '退款',
          value: '1'
        },
        {
          label: '退货退款',
          value: '2'
        },
        {
          label: '订单取消',
          value: '4'
        }
      ],
      multipleSelection: [],
      sendWay: false,
      params: {
        limit: 20,
        page: 1,
        id: '',
        orderId: '',
        date: []
      },
      totalCount: 0
    }
  },
  components: {
    SendModal,
    AfterDetailModal
  },
  created() {
    this.getData()
  },
  methods: {
    parentClear() {
      const filtersForm = this.$refs.filtersForm
      filtersForm && filtersForm.resetFields()
      this.getData(true)
    },
    async cancelHandler(row) {
      try {
        await this.$confirm('是否要确认撤销该申请？', '提示', {
          type: 'warning',
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })

        try {
          this.loading = true
          await this.$api.productPurchase.cancelOrder({
            afterSaleOrderId: row.id
          })
          this.$message.success('撤销成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => ({
        id: _.id,
        id: _.id
      }))
    },
    async confirmHandler(row) {
      try {
        await this.$confirm(`点击确认后完成确认收货,请确认货物已送达`, '提示', {
          type: 'warning',
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        })

        try {
          this.loading = true
          await this.$api.productPurchase.confirmReceive({
            afterSaleOrderId: row.id
          })
          this.$message.success('确认收货成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async passHandler(row) {
      try {
        await this.$confirm(
          `是否同意对售后订单进行${row.typeStr || '申请'}操作?`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true
          await this.$api.productPurchase.pass({
            id: row.id,
            type: row.type
          })
          this.$message.success('操作成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    rejectHandler(row) {
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        inputPattern: /\S+/,
        inputErrorMessage: '请输入拒绝原因'
      })
        .then(async ({ value }) => {
          this.loading = true

          try {
            await this.$api.productPurchase.reject({
              id: row.id,
              type: row.type,
              rejectMsg: value
            })
            this.$message.success('操作成功！')
            this.getData(true)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async getData(query) {
      try {
        this.loading = true
        let params = { ...this.params }
        if (query) {
          params.limit = 20
          params.page = 1
        }

        params.currentPage = params.page
        params.pageSize = params.limit

        for (let i in params) {
          if (params[i] === '') params[i] = undefined
        }
        if (params.date && params.date.length) {
          params.applyStartTime = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.applyEndTime = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.applyStartTime = undefined
          params.applyEndTime = undefined
        }
        const { date, limit, page, ...sendData } = params
        const res = await this.$api.productPurchase.getAfterList(sendData)
        this.tableData = ((res.page && res.page.list) || []).map(item => {
          item.statusStr = this.statusObj[+item.storeState]
          item.typeStr = this.typeObj[+item.type]
          return item
        })
        this.totalCount = res.page.total
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    sendHandler(row, type) {
      this.currentItem = row
      this.isDetail = type
      this.visible = true
    },

    detailHandler(row) {
      this.currentItem = row
      this.detailVisible = true
    },

    async exportData() {
      try {
        this.loading = true

        const params = { ...this.params }

        if (params.date && params.date.length) {
          params.applyStartTime = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD HH:mm:ss'
          )
          params.applyEndTime = this.$utils.dateFormat(
            params.date[1],
            'YYYY-MM-DD HH:mm:ss'
          )
        } else {
          params.applyStartTime = undefined
          params.applyEndTime = undefined
        }

        await this.$api.productPurchase.exportAfterList({
          applyStartTime: params.applyStartTime,
          applyEndTime: params.applyEndTime,
          deliverGoods: this.multipleSelection.length
            ? this.multipleSelection
            : undefined,
          id: params.id || undefined,
          storeState: params.storeState || undefined
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
