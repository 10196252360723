<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="申请售后"
    :visible.sync="show"
    width="90vw"
  >
    <div class="itemWrap">
      <!-- <el-divider direction="horizontal" content-position="left"
        >售后商品明细</el-divider
      > -->

      <el-table
        max-height="250"
        :data="tableData"
        style="width: 100%; margin-bottom: 10px"
      >
        <el-table-column prop="goodsName" label="商品名称" />
        <el-table-column prop="barNo" label="条形码" />
        <el-table-column prop="purchaseUnit" label="采购单位" />
        <el-table-column show-overflow-tooltip label="规格">
          <template slot-scope="{ row }">
            <span>{{ row.specification || row.purchaseSpecification }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="inPrice" label="采购单价(元)" />
        <el-table-column prop="goodsNum" label="采购数量" />
        <el-table-column min-width="110" label="售后数量">
          <template slot-scope="{ row }">
            <el-input-number
              @blur="
                () => {
                  !row.applyNum ? (row.applyNum = 1) : ''
                }
              "
              :min="1"
              :max="row.goodsNum"
              size="mini"
              v-model="row.applyNum"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="payPrice" label="金额小计(元)">
          <template slot-scope="{ row }">
            <span>{{
              Number(Number(row.applyNum) * Number(row.inPrice)).toFixed(2)
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <el-divider direction="horizontal" content-position="left"
      >售后描述</el-divider
    > -->
    <div class="itemWrap" style="display: flex">
      <el-form label-position="left" label-width="90px" size="small">
        <el-form-item label="售后原因：">
          <el-input
            style="width: 250px"
            type="textarea"
            placeholder="请输入退换货原因"
            v-model="form.reason"
            maxlength="60"
            rows="4"
            show-word-limit
            resize="none"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="图片凭证：">
          <el-upload
            ref="upload"
            class="upload-demo"
            :action="'/purchase/fileUpload/upload?token=' + token"
            :on-preview="handlePictureCardPreview"
            :file-list="imgsList"
            :multiple="true"
            :on-success="successHandler"
            :on-remove="removeHandler"
            :limit="5"
            list-type="picture-card"
          >
            <i class="el-icon-plus"></i>
            <template #tip>
              <div class="el-upload__tip">最多可上传5张</div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog
      class="bigImgWrap"
      append-to-body
      :visible.sync="imgDialogVisible"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>

      <el-button type="primary" @click="submit(3)" :loading="btnLoading"
        >换货</el-button
      >
      <el-button type="primary" @click="submit(1)" :loading="btnLoading"
        >退款</el-button
      >
      <el-button type="primary" @click="submit(2)" :loading="btnLoading"
        >退款退货</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  props: ['purchaseNo', 'isChild'],
  mixins: [dialogCommonParams],
  data() {
    return {
      form: {
        reason: '',
        afterSalePictureDtoList: []
      },
      targetItem: {},
      tableData: [],
      imgsList: [],
      imgDialogVisible: false,
      dialogImageUrl: '',
      loading: false,
      btnLoading: false
    }
  },
  created() {
    this.getDetail()
  },
  computed: {
    token() {
      return localStorage.getItem('token')
    }
  },
  methods: {
    async submit(type) {
      try {
        await this.$confirm(
          `确定发起${
            type == 3 ? '换货' : type == 1 ? '退款' : '退款退货'
          }申请？`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        const sendData = { ...this.form }

        sendData.type = type

        sendData.orderId = this.purchaseNo

        sendData.afterSaleGoodsDtoList = this.tableData.map(item => ({
          goodsId: item.goodsId,
          goodsName: item.goodsName,
          purchasePrice: item.inPrice,
          applyNum: item.applyNum
        }))

        try {
          this.loading = true

          await this.$api.productPurchase.afterSell(sendData)
          this.show = false
          this.$emit('getData')
          this.$message.success('操作成功！')
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    successHandler(file, fileList) {
      this.form.afterSalePictureDtoList.push({
        picturePath: file.url
      })
    },
    removeHandler(file, fileList) {
      this.form.afterSalePictureDtoList = fileList.map(item => ({
        picturePath: item.response.url
      }))
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.imgDialogVisible = true
    },
    async getDetail() {
      try {
        this.loading = true

        const sendData = {
          purchaseNo: this.purchaseNo
        }

        const r = this.isChild
          ? await this.$api.productPurchase.getChildOrderDetail(sendData)
          : await this.$api.productPurchase.getOrderDetail(sendData)

        this.tableData = (r.detailList || []).map(item => {
          item.applyNum = 1
          return item
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.itemWrap {
  margin-bottom: 12px;
  h4 {
    margin-bottom: 5px;
  }
}
</style>
