<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="采购付款"
    :visible.sync="show"
    width="650px"
  >
    <el-form :model="params" ref="form" label-width="100px">
      <el-form-item label="采购订单号">
        <span>{{ purchaseNo }}</span>
      </el-form-item>

      <el-form-item label="订单金额">
        <span>{{ totalPrice }}</span>
      </el-form-item>

      <el-form-item label="支付方式">
        <!-- <el-radio v-model="payType" label="1">工行卡支付</el-radio> -->
        <el-radio v-if="scanCode" v-model="payType" label="0"
          >扫码支付</el-radio
        >
        <el-radio v-if="supportPayOffline" v-model="payType" label="2"
          >线下支付</el-radio
        >
        <el-radio v-if="balance" v-model="payType" label="3"
          >余额/赊销</el-radio
        >
      </el-form-item>
      <el-form-item label="付款备注">
        <el-input
          type="textarea"
          resize="none"
          placeholder="请输入内容"
          v-model="payRemark"
          maxlength="50"
          show-word-limit
        >
        </el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="PayModalConfirm"
        >确定</el-button
      >
    </span>

    <ScanCodeModal
      v-if="scanCodeVisible"
      :purchaseNo="purchaseNo"
      :payRemark="payRemark"
      :visible.sync="scanCodeVisible"
      @emitHandler="emitHandler"
    />

    <CardFreePaymentModal
      v-if="cardFreePaymentVisible"
      :visible.sync="cardFreePaymentVisible"
    />

    <NoCardFreePaymentModal
      v-if="noCardFreePaymentVisible"
      :visible.sync="noCardFreePaymentVisible"
      :payRemark="payRemark"
      :lastCardNo="lastCardNo"
      :payOrderObj="payOrderObj"
      @emitHandler="emitHandler"
    />

    <PayOffline
      v-if="payOfflineVisible"
      :visible.sync="payOfflineVisible"
      :payRemark="payRemark"
      :lastCardNo="lastCardNo"
      :payOrderObj="payOrderObj"
      @emitHandler="emitHandler"
    />

    <BalancePayment
      v-if="balancePaymentVisible"
      :visible.sync="balancePaymentVisible"
      :payRemark="payRemark"
      :lastCardNo="lastCardNo"
      :payOrderObj="payOrderObj"
      @emitHandler="emitHandler"
    />

    <PasswordModal :visible.sync="passwordVisible" v-if="passwordVisible" />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import PasswordModal from './PasswordModal'
import ScanCodeModal from './ScanCodeModal'
import CardFreePaymentModal from './CardFreePaymentModal'
import NoCardFreePaymentModal from './NoCardFreePaymentModal'
import PayOffline from './PayOffline'
import BalancePayment from './BalancePayment'

export default {
  mixins: [dialogCommonParams],
  props: ['purchaseNo'],
  components: {
    PasswordModal,
    ScanCodeModal,
    CardFreePaymentModal,
    NoCardFreePaymentModal,
    PayOffline,
    BalancePayment
  },
  data() {
    return {
      scanCode: false,
      supportPayOffline: false,
      balance: false,
      totalPrice: 0,
      loading: false,
      payqrcode: null,
      passwordVisible: false,
      scanCodeVisible: false,
      cardFreePaymentVisible: false,
      noCardFreePaymentVisible: false,
      payOfflineVisible: false,
      balancePaymentVisible: false,
      payRemark: '',
      payType: '',
      payOrderObj: {},
      params: {},
      cardFreePaymentForm: { amount: 1, idType: 0 }
    }
  },
  created() {
    this.getData()
  },
  computed: {
    lastCardNo() {
      return (
        (this.cardFreePaymentForm.bankCardNo &&
          this.cardFreePaymentForm.bankCardNo.substr(-4)) ||
        ''
      )
    },
    currentNumTxt() {
      return !this.numberTxt ? '获取验证码' : this.numberTxt + 's'
    }
  },
  methods: {
    async getData() {
      try {
        this.loading = true

        const r = await this.$api.productPurchase.getPayOrder({
          purchaseNo: this.purchaseNo
        })

        if (r.scanCode) {
          this.scanCode = true
        }
        if (r.supportPayOffline) {
          this.supportPayOffline = true
        }

        if (r.balance) {
          this.balance = true
        }

        this.payOrderObj = r
        this.purchaseNo = r.purchaseNo
        this.totalPrice = r.totalPrice
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async PayModalConfirm() {
      if (this.payType == '') {
        this.$message.warning('目前没有支付方式')
        return
      }

      if (this.payType == '0') {
        this.scanCodeHandler()
      } else if (this.payType == '1') {
        this.cardFreePaymentHandler()
      } else if (this.payType == '2') {
        this.payOfflineVisible = true
      } else {
        this.loading = true

        try {
          const sendData = {
            payRemark: this.payRemark,
            purchaseNo: this.payOrderObj.purchaseNo
          }

          const res = await this.$api.productPurchase.balancePayHandler(
            sendData
          )

          if (res.code == 1001) {
            this.passwordVisible = true
          } else {
            this.balancePaymentVisible = true
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    async cardFreePaymentHandler() {
      try {
        const res = await this.$api.productPurchase.cardFreePayment()

        if (!res.storeSignEntity) {
          this.cardFreePaymentVisible = true
        } else {
          this.noCardFreePaymentVisible = true
          this.cardFreePaymentForm = Object.assign({}, res.storeSignEntity)
        }
      } catch (e) {
        console.log(e)
      }
    },
    scanCodeHandler() {
      this.scanCodeVisible = true
    },
    emitHandler() {
      this.show = false
      this.$emit('getData')
    }
  }
}
</script>
