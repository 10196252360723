<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="扫码支付"
    :visible.sync="show"
    width="500px"
    v-loading="loading"
  >
    <div class="form-group" style="position: relative; margin-top: 40px">
      <div
        class="qrcode"
        id="payqrcode"
        style="width: 256px; height: 256px; margin: 0 auto; text-align: center"
      ></div>

      <div class="outDate-modal" v-if="outDateModalShow">
        <div class="tips">
          <p>付款码已过期，请重新获取</p>
          <a class="btn btn-primary" id="refreshQrCode" @click="refreshQrCode"
            ><i class="fa fa-refresh"></i
          ></a>
        </div>
      </div>
    </div>
    <div style="text-align: center; margin-top: 10px">
      <span
        >付款码将在 <b>{{ outDate }}</b> 后过期</span
      >
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeHandler">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import QRCode from 'qrcodejs2'

export default {
  mixins: [dialogCommonParams],
  props: ['purchaseNo', 'payRemark'],
  data() {
    return {
      outDateModalShow: false,
      timer: null,
      outdateTimer: null,
      outDate: '',
      loading: false,
      payqrcode: null
    }
  },
  created() {
    this.$nextTick(() => {
      if (!this.payqrcode) {
        this.payqrcode = new QRCode('payqrcode', {
          width: 256,
          height: 256
        })
      }

      this.outDate = this.$utils.dateFormat(
        new Date(Date.now() + 10 * 60 * 1000),
        'YYYY-MM-DD HH:mm'
      )

      this.outDateModalShow = false

      this.payHandler()
    })
  },
  methods: {
    closeHandler() {
      clearInterval(this.timer)
      clearTimeout(this.outdateTimer)
      this.timer = null
      this.outdateTimer = null
      this.show = false
    },
    refreshQrCode: function () {
      this.payHandler()
    },
    closePayModal() {
      this.closeHandler()
      this.$emit('emitHandler')
    },
    async payHandler() {
      try {
        const r = await this.$api.productPurchase.getPayOrder({
          purchaseNo: this.purchaseNo
        })

        if (r.qrCode && this.payqrcode) {
          this.payqrcode.makeCode(
            location.origin +
              `/#/payCode?payAmt=${r.payAmt}&purchaseNo=${
                r.purchaseNo
              }&qrCode=${r.qrCode}&transactionId=${
                r.transactionId
              }&token=${localStorage.getItem(
                'token'
              )}&payRemark=${encodeURIComponent(this.payRemark)}`
          )
        }

        this.outdateTimer = setTimeout(() => {
          this.outDateModalShow = true
          clearInterval(this.timer)
        }, 10 * 60 * 1000)

        this.timer = setInterval(async () => {
          const r = await this.$api.productPurchase.getPayStatus({
            purchaseNo: this.purchaseNo
          })

          if (+r.payStatus === 1) {
            clearInterval(this.timer)
            this.timer = null
            this.$alert('付款成功！', '提示', {
              confirmButtonText: '确定',
              type: 'success',
              callback: action => {
                this.closePayModal()
              }
            })
          }
        }, 1000)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.outDate-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
}

.outDate-modal .tips {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  font-weight: bold;
  font-size: 16px;
}
</style>
