<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="工商银行无卡支付"
    :visible.sync="show"
    width="550px"
  >
    <div>
      使用尾号{{ lastCardNo }}工行卡付款
      <b class="payTxt">￥{{ payOrderObj.totalPrice }}</b
      >元，点击确定将完成下单。
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button
        type="primary"
        @click="noCardFreePaymentHandler"
        :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['payRemark', 'payOrderObj', 'lastCardNo'],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async noCardFreePaymentHandler() {
      this.loading = true

      try {
        const sendData = {
          payRemark: this.payRemark,
          payType: 1,
          payAmt: this.payOrderObj.payAmt,
          purchaseNo: this.payOrderObj.purchaseNo,
          qrCode: this.payOrderObj.qrCode,
          transactionId: this.payOrderObj.transactionId
        }

        await this.$api.productPurchase.noCardFreePayment(sendData)

        this.$message.success('支付成功')
        this.show = false
        this.$emit('emitHandler')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.payTxt {
  font-size: 20px;
  color: red;
}
</style>
