<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="提示"
    :visible.sync="show"
    width="600px"
  >
    <p class="tipsWrap">当前门店账户可用余额不足</p>
    <el-table
      v-loading="loading"
      :data="tableData"
      max-height="300"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        prop="supplierName"
        label="供应商名称"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="canUseAmount"
        label="可用余额(元)"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="canUseTotal"
        label="可用赊销额度(元)"
      >
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      tableData: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true

        const res = await this.$api.productPurchase.queryBalance()

        this.tableData = (res.memberBalance || []).map(item => {
          return {
            supplierName: item.supplierName,
            canUseAmount: item.balance,
            canUseTotal:
              item.balance > 0
                ? item.maxLimit
                : Number(item.maxLimit) + Number(item.balance)
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tipsWrap {
  margin-bottom: 10px;
  margin-left: 10px;
  font-weight: bold;
}
</style>
