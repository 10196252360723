<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="商品详情"
    :visible.sync="show"
    width="500px"
    v-loading="loading"
  >
    <div class="specialInfo" style="border: none">
      <div class="content">
        <div class="leftInfo">
          <el-image
            style="width: 180px; height: 160px"
            :src="currentItem.goodsImg"
          >
          </el-image>
          <div class="goodsName detailName">
            {{ currentItem.goodsName || '-' }}
          </div>

          <div class="goodsPrice">
            <span style="font-weight: bold">采购金额：</span> ￥{{
              (+currentItem.purchasePrice * +currentItem.goodsNum).toFixed(2) ||
              0
            }}
          </div>
        </div>
        <div class="rightInfo">
          <p>
            <span class="name">供应商：</span>
            <span class="value">{{ currentItem.supplierName || '-' }}</span>
          </p>
          <p>
            <span class="name">品牌：</span>
            <span class="value">{{ currentItem.brandName || '-' }}</span>
          </p>
          <p>
            <span class="name">采购单位：</span>
            <span class="value">{{ currentItem.purchaseUnit || '-' }}</span>
          </p>
          <p>
            <span class="name">采购规格：</span>
            <span class="value">{{
              currentItem.purchaseSpecification || '-'
            }}</span>
          </p>
          <p>
            <span class="name">采购单价：</span>
            <span class="value"
              >￥{{ currentItem.purchasePriceStr || '-' }}</span
            >
          </p>
          <p>
            <span class="name">零售单位：</span>
            <span class="value">{{ currentItem.unit || '-' }}</span>
          </p>
          <p>
            <span class="name">零售规格：</span>
            <span class="value">{{ currentItem.specification || '-' }}</span>
          </p>
          <p>
            <span class="name">建议零售价：</span>
            <span class="value"
              >￥{{ currentItem.suggestedRetailPrice || 0 }}</span
            >
          </p>

          <p>
            <span class="name">条形码：</span>
            <span class="value">{{ currentItem.barNo || '-' }}</span>
          </p>

          <p>
            <span class="name">颜色：</span>
            <span class="value">{{ currentItem.goodsColor || '-' }}</span>
          </p>

          <p>
            <span class="name">尺码：</span>
            <span class="value">{{ currentItem.goodsSize || '-' }}</span>
          </p>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['currentItem'],
  data() {
    return {
      loading: false
    }
  }
}
</script>

<style lang="scss" scoped>
.specialInfo {
  word-break: break-word;
  border: 1px solid #ccc;
  padding-top: 10px;
  margin-bottom: 5px;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
  margin-right: 10px;
  .content {
    display: flex;
    width: 100%;

    .rightInfo {
      margin-left: 30px;
    }

    .rightInfo p {
      display: flex;
      margin: 0 0 7px !important;
      .name {
        width: 90px;
        font-weight: bold;
        flex-shrink: 0;
      }
    }
  }
  .detailName {
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
    max-width: 182px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
</style>
