<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="发货信息"
    :visible.sync="show"
    width="450px"
  >
    <div class="content">
      <el-form
        style="margin-top: 20px; width: 300px"
        label-width="100px"
        :model="form"
        :rules="rules"
        ref="ruleForm"
      >
        <template v-if="isDetail">
          <el-form-item label="订单编号：">
            <span>{{ form.purchaseNo }}</span>
          </el-form-item>

          <el-form-item label="配送方式：">
            <span>{{
              +form.type === 3
                ? '快递配送'
                : +form.type === 2
                ? '零单物流'
                : +form.type === 1
                ? '整车物流'
                : '-'
            }}</span>
          </el-form-item>

          <template v-if="+form.type === 1">
            <el-form-item label="车牌号：">
              <span>{{ form.carNo }}</span>
            </el-form-item>

            <el-form-item label="联系电话：">
              <span>{{ form.phone }}</span>
            </el-form-item>
          </template>

          <template v-if="+form.type === 2">
            <el-form-item label="快递公司：">
              <span>{{ form.logisticsCompany }}</span>
            </el-form-item>
            <el-form-item label="联系电话：">
              <span>{{ form.phone }}</span>
            </el-form-item>
          </template>

          <template v-if="form.type === 3">
            <el-form-item label="快递公司：">
              <span>{{ form.logisticsCompany }}</span>
            </el-form-item>

            <el-form-item label="快递编号：">
              <span>{{ form.logisticsNo }}</span>
            </el-form-item>
          </template>
        </template>

        <template v-else>
          <el-form-item label="配送方式" prop="type">
            <el-select v-model="form.type" clearable @change="typeChange">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <template v-if="form.type === 1">
            <el-form-item label="车牌号" prop="carNo">
              <el-input v-model.trim="form.carNo" placeholder="请输入车牌号" />
            </el-form-item>

            <el-form-item label="联系电话" prop="phone">
              <el-input
                type="number"
                v-model="form.phone"
                placeholder="请输入联系电话"
              />
            </el-form-item>
          </template>

          <template v-if="form.type === 2">
            <el-form-item label="快递公司" prop="logisticsCompany">
              <el-input
                v-model.trim="form.logisticsCompany"
                placeholder="请输入快递公司"
              />
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入联系电话" />
            </el-form-item>
          </template>

          <template v-if="form.type === 3">
            <el-form-item label="快递公司" prop="logisticsCompany">
              <el-input
                v-model.trim="form.logisticsCompany"
                placeholder="请输入快递公司"
              />
            </el-form-item>

            <el-form-item label="快递单号" prop="logisticsNo">
              <el-input
                v-model="form.logisticsNo"
                placeholder="请输入快递单号"
              />
            </el-form-item>
          </template>
        </template>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="show = false">关闭</el-button>
        <el-button
          v-if="!isDetail"
          type="primary"
          @click="submit"
          :loading="btnLoading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['isDetail'],
  data() {
    const _this = this

    return {
      rules: {
        type: [{ required: true, trigger: 'blur', message: '请选择配送方式' }],
        logisticsCompany: [
          { required: true, trigger: 'blur', message: '请输入快递公司' }
        ],
        logisticsNo: [
          { required: true, trigger: 'blur', message: '请输入快递单号' }
        ],
        carNo: [{ required: true, trigger: 'blur', message: '请输入车牌号' }],
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('请输入联系电话'))

              if (!_this.$utils.isPhone(value)) {
                return callback(new Error('手机号不正确'))
              }
              callback()
            }
          }
        ]
      },
      form: {
        type: 3,
        logisticsCompany: '',
        logisticsNo: '',
        carNo: '',
        phone: ''
      },
      typeList: [
        {
          label: '快递配送',
          value: 3
        },
        {
          label: '整车物流',
          value: 1
        }
        // {
        //   label: '零单物流',
        //   value: 2
        // }
      ],
      btnLoading: false,
      params: {}
    }
  },
  created() {
    if (this.isDetail) {
      // this.getLogistics({
      //   orderId: this.currentItem.orderId,
      //   id: this.currentItem.id
      // })
    }
  },
  methods: {
    async getLogistics(params) {
      try {
        const res = await this.$api.afterOrderList.getLogistics(params)
        if (res.entity) {
          for (let i in this.form) {
            this.form[i] = res.entity[i]
          }
        }
      } catch (err) {
        console.log(err)
      }
    },

    typeChange() {
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },

    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.btnLoading = true

          const sendData = JSON.parse(JSON.stringify(this.form))

          sendData.afterSaleId = this.currentItem.id

          try {
            await this.$api.productPurchase.saveLogisticsInfo(sendData)

            this.$message.success('保存成功')
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
}

.flex {
  display: flex;
  align-items: center;
  i {
    font-size: 25px;
    margin-left: 4px;
    cursor: pointer;
  }
}

.tips {
  text-align: center;
  margin-left: 60px;
  font-size: 13px;
  color: #999;
}
</style>
