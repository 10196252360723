<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="详情"
    :visible.sync="show"
    width="90vw"
  >
    <div class="itemWrap">
      <h4>售后信息</h4>
      <el-form inline size="small">
        <el-form-item label="售后类型：">
          <span>{{ currentItem.typeStr || '-' }}</span>
        </el-form-item>
        <el-form-item style="margin: 0 20px" label="售后单号：">
          <span>{{ currentItem.id || '-' }}</span>
        </el-form-item>
        <el-form-item label="申请时间：">
          <span>{{ currentItem.applyTime || '-' }}</span>
        </el-form-item>
      </el-form>
    </div>

    <!-- <div class="itemWrap">
      <h4>申请人信息</h4>
      <el-form inline size="small">
        <el-form-item label="商户名称：">
          <span>{{ targetItem.merchantName || '-' }}</span>
        </el-form-item>
        <el-form-item style="margin: 0 20px" label="联系人：">
          <span>{{ targetItem.linkMan || '-' }}</span>
        </el-form-item>
        <el-form-item label="联系电话：">
          <span>{{ targetItem.linkPhone || '-' }}</span>
        </el-form-item>
      </el-form>
    </div> -->

    <div class="itemWrap">
      <h4>关联支付订单</h4>
      <el-form inline size="small">
        <el-form-item label="订单编号：">
          <span>{{ currentItem.orderId || '-' }}</span>
        </el-form-item>
        <el-form-item style="margin: 0 20px" label="订单状态：">
          <span>{{ currentItem.statusStr || '-' }}</span>
        </el-form-item>
      </el-form>
    </div>
    <div class="itemWrap" style="display: flex">
      <div style="width: 50%">
        <h4>售后描述</h4>
        <el-form label-position="left" label-width="90px" size="small">
          <el-form-item label="售后原因：">
            <span>{{ targetItem.reason || '-' }}</span>
          </el-form-item>
          <el-form-item label="图片凭证：">
            <template
              v-if="targetItem.imagesList && targetItem.imagesList.length"
            >
              <el-image
                @click="
                  () => {
                    dialogImageUrl = item.src
                    imgDialogVisible = true
                  }
                "
                v-for="(item, index) in targetItem.imagesList"
                :key="index"
                style="
                  width: 100px;
                  height: 100px;
                  margin-right: 10px;
                  cursor: zoom-in;
                "
                :src="item.src"
              >
              </el-image>
            </template>
            <span v-else>-</span>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <h4>店铺答复</h4>
        <el-form label-position="left" label-width="120px" size="small">
          <template v-if="[1, 2, 3, 4].includes(+targetItem.state)">
            <p style="color: green">已同意</p>
            <el-form-item label="收货人：">
              <span>{{ targetItem.receiver || '-' }}</span>
            </el-form-item>
            <el-form-item label="收货人联系电话：">
              <span>{{ targetItem.receiverPhone || '-' }}</span>
            </el-form-item>
            <el-form-item label="收货地址：">
              <span>{{ targetItem.receiveAddr || '-' }}</span>
            </el-form-item>
          </template>

          <template v-if="targetItem.state == 5">
            <p style="color: red">已拒绝</p>
            <el-form-item label="拒绝原因：">
              <span>{{ targetItem.rejectReason || '-' }}</span>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>

    <div
      v-if="currentItem.typeStr == '退货退款' || currentItem.typeStr == '换货'"
      class="itemWrap"
      style="display: flex"
    >
      <div style="width: 50%">
        <h4>供应商物流</h4>
        <el-form
          style="width: 50%"
          label-position="left"
          label-width="90px"
          size="small"
        >
          <el-form-item label="配送方式：">
            <span>{{
              +targetItem.supplierAfterSaleLogisticsResVo.type === 3
                ? '快递配送'
                : +targetItem.supplierAfterSaleLogisticsResVo.type === 2
                ? '零单物流'
                : +targetItem.supplierAfterSaleLogisticsResVo.type === 1
                ? '整车物流'
                : '-'
            }}</span>
          </el-form-item>

          <template
            v-if="+targetItem.supplierAfterSaleLogisticsResVo.type === 1"
          >
            <el-form-item label="车牌号：">
              <span>{{
                targetItem.supplierAfterSaleLogisticsResVo.carNo || '-'
              }}</span>
            </el-form-item>

            <el-form-item label="联系电话：">
              <span>{{
                targetItem.supplierAfterSaleLogisticsResVo.phone || '-'
              }}</span>
            </el-form-item>
          </template>

          <template
            v-if="+targetItem.supplierAfterSaleLogisticsResVo.type === 2"
          >
            <el-form-item label="快递公司：">
              <span>{{
                targetItem.supplierAfterSaleLogisticsResVo.logisticsCompany ||
                '-'
              }}</span>
            </el-form-item>
            <el-form-item label="联系电话：">
              <span>{{
                targetItem.supplierAfterSaleLogisticsResVo.phone || '-'
              }}</span>
            </el-form-item>
          </template>

          <template
            v-if="targetItem.supplierAfterSaleLogisticsResVo.type === 3"
          >
            <el-form-item label="快递公司：">
              <span>{{
                targetItem.supplierAfterSaleLogisticsResVo.logisticsCompany ||
                '-'
              }}</span>
            </el-form-item>

            <el-form-item label="快递编号：">
              <span>{{
                targetItem.supplierAfterSaleLogisticsResVo.logisticsNo || '-'
              }}</span>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div>
        <h4>门店物流</h4>
        <el-form label-position="left" label-width="90px" size="small">
          <el-form-item label="配送方式：">
            <span>{{
              +targetItem.storeAfterSaleLogisticsResVo.type === 3
                ? '快递配送'
                : +targetItem.storeAfterSaleLogisticsResVo.type === 2
                ? '零单物流'
                : +targetItem.storeAfterSaleLogisticsResVo.type === 1
                ? '整车物流'
                : '-'
            }}</span>
          </el-form-item>

          <template v-if="+targetItem.storeAfterSaleLogisticsResVo.type === 1">
            <el-form-item label="车牌号：">
              <span>{{
                targetItem.storeAfterSaleLogisticsResVo.carNo || '-'
              }}</span>
            </el-form-item>

            <el-form-item label="联系电话：">
              <span>{{
                targetItem.storeAfterSaleLogisticsResVo.phone || '-'
              }}</span>
            </el-form-item>
          </template>

          <template v-if="+targetItem.storeAfterSaleLogisticsResVo.type === 2">
            <el-form-item label="快递公司：">
              <span>{{
                targetItem.storeAfterSaleLogisticsResVo.logisticsCompany || '-'
              }}</span>
            </el-form-item>
            <el-form-item label="联系电话：">
              <span>{{
                targetItem.storeAfterSaleLogisticsResVo.phone || '-'
              }}</span>
            </el-form-item>
          </template>

          <template v-if="targetItem.storeAfterSaleLogisticsResVo.type === 3">
            <el-form-item label="快递公司：">
              <span>{{
                targetItem.storeAfterSaleLogisticsResVo.logisticsCompany || '-'
              }}</span>
            </el-form-item>

            <el-form-item label="快递编号：">
              <span>{{
                targetItem.storeAfterSaleLogisticsResVo.logisticsNo || '-'
              }}</span>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>

    <div class="itemWrap">
      <h4>售后商品明细</h4>
      <el-table
        max-height="250"
        :data="targetItem.afterSaleGoodsResVoList"
        style="width: 100%; margin-bottom: 10px"
      >
        <el-table-column prop="goodsName" label="商品名称" />
        <el-table-column prop="barNo" label="条形码" />
        <el-table-column prop="purchaseUnit" label="采购单位" />
        <el-table-column prop="purchaseSpecification" label="规格" />
        <el-table-column prop="purchasePrice" label="采购单价(元)" />
        <el-table-column prop="goodsNum" label="采购数量" />
        <el-table-column prop="applyNum" label="售后数量" />
        <el-table-column prop="payPrice" label="金额小计(元)">
          <template slot-scope="{ row }">
            <span>{{
              Number(Number(row.applyNum) * Number(row.purchasePrice)).toFixed(
                2
              )
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      class="bigImgWrap"
      append-to-body
      :visible.sync="imgDialogVisible"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      targetItem: {
        supplierAfterSaleLogisticsResVo: {}
      },
      tableData: [],
      imgDialogVisible: false,
      dialogImageUrl: '',
      loading: false,
      btnLoading: false
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      try {
        this.loading = true

        const res = await this.$api.productPurchase.afterDetail({
          afterSaleOrderId: this.currentItem.id
        })

        if (res.afterSaleOrderInfoResVo.storeAfterSalePictureResVoList) {
          res.afterSaleOrderInfoResVo.imagesList =
            res.afterSaleOrderInfoResVo.storeAfterSalePictureResVoList.map(
              item => {
                return {
                  src: this.$utils.getImg(item.picturePath)
                }
              }
            )
        }

        res.afterSaleOrderInfoResVo.supplierAfterSaleLogisticsResVo =
          res.afterSaleOrderInfoResVo.supplierAfterSaleLogisticsResVo || {}

        res.afterSaleOrderInfoResVo.storeAfterSaleLogisticsResVo =
          res.afterSaleOrderInfoResVo.storeAfterSaleLogisticsResVo || {}

        res.afterSaleOrderInfoResVo.receiveAddr =
          (res.afterSaleOrderInfoResVo.provinceName || '') +
          (res.afterSaleOrderInfoResVo.cityName || '') +
          (res.afterSaleOrderInfoResVo.regionName || '') +
          (res.afterSaleOrderInfoResVo.address || '')

        this.targetItem = res.afterSaleOrderInfoResVo
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
}

::v-deep .el-form-item {
  margin-bottom: 5px !important;
}

::v-deep .el-form-item__label {
  padding: 0 !important;
}

.itemWrap {
  margin-bottom: 12px;
  h4 {
    margin-bottom: 5px;
  }
}
</style>
