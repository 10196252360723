<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="付款记录"
    :visible.sync="show"
    width="400px"
  >
    <div v-loading="loading">
      <el-form size="small" label-width="100px" :model="currentPayRecord">
        <el-form-item label="付款人：">
          <span>{{ currentPayRecord.payName || '-' }}</span>
        </el-form-item>
        <el-form-item label="付款金额：">
          <span>￥{{ currentPayRecord.payPrice || '0' }}</span>
        </el-form-item>
        <el-form-item label="付款日期：">
          <span>{{ currentPayRecord.payTime || '-' }}</span>
        </el-form-item>
        <el-form-item label="付款备注：">
          <span>{{ currentPayRecord.payRemark || '-' }}</span>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['purchaseNo'],
  data() {
    return {
      loading: false,
      currentPayRecord: {}
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const r = await this.$api.productPurchase.queryPayRecord({
          purchaseNo: this.purchaseNo
        })
        this.currentPayRecord = r.entity || {}
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
